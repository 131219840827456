import React, { useEffect, useState } from 'react';
import GraphiQL from 'graphiql';
import 'graphiql/graphiql.css';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { Context } from '../../index';

export const graphQLFetcher = (id, documentationType) => (graphQLParams) => {
  window.analytics?.track('graphi-ql box run', { id });
  return fetch(`/api/v1/docs/${documentationType}`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(graphQLParams),
  }).then((response) => response.json());
};

const GraphiQLRenderer = ({ value, variables, introspectionResult, id }) => {
  const { documentationType } = React.useContext(Context);
  const [currentQuery, setCurrentQuery] = useState('');

  const location = useLocation();

  useEffect(() => {
    setCurrentQuery(value);
  }, [location.pathname]);

  return (
    <div
      style={{
        width: '100%',
        height: 500,
        overflow: 'hidden',
        marginBottom: 20,
      }}
    >
      <GraphiQL
        query={currentQuery}
        fetcher={graphQLFetcher(id, documentationType)}
        onEditQuery={(query) => setCurrentQuery(query)}
        docExplorerOpen={false}
        defaultVariableEditorOpen={variables ? true : false}
        defaultSecondaryEditorOpen={false}
        schema={introspectionResult}
        variables={variables ? variables : ''}
      />
    </div>
  );
};

function mapStateToProps(state) {
  const { schema } = state.coreAPI;

  return { introspectionResult: schema };
}

export default connect(mapStateToProps)(GraphiQLRenderer);
