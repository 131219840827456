const page2ImportantNotes = [
  { level: 1, component: 'Page', value: `Important Notes & Best Practices` },
  { level: 2, component: 'SubHeading', value: `IDE's and Dev Tools` },
  {
    level: 3,
    component: 'Text',
    value:
      'Cherre recommends leveraging a GraphQL IDE to develop your queries.  The strong autocomplete, schema awareness, and graphical user interface will eliminate most syntax errors and will shorten query development time significantly',
  },
  {
    level: 3,
    component: 'Text',
    value: 'A few of Cherre’s favorites are...',
  },
  {
    level: 3,
    component: 'UnorderedList',
    value: `[[https://graphiql-online.com|GraphiQL]]
    [[https://chrome.google.com/webstore/detail/graphql-playground-for-ch/kjhjcgclphafojaeeickcokfbhlegecd|GraphQL Playground (Chrome)]]
    [[https://sourceforge.net/projects/graphql-playground.mirror/|GraphQL Playground]]
    [[https://www.postman.com/|Postman]]
`,
  },
  {
    level: 3,
    component: 'Text',
    value:
      'It is important to note that these IDEs have different response size limitations, therefore, using high query limits might result in a UI slow-down in some instances',
  },
  {
    level: 2,
    component: 'SubHeading',
    value: `Making Queries Reusable with Variables`,
  },
  {
    level: 3,
    component: 'Text',
    value: 'Graphql queries can receive user input in one of two ways',
  },
  {
    level: 3,
    component: 'OrderedList',
    value: `The user’s filter criteria can be hard coded directly into the query (Suboptimal)
    The user’s filter criteria can be passed to the query using a graphql $variable (Optimal)`,
  },
  {
    level: 3,
    component: 'SubHeading',
    value: 'Example - Hardcoded Criteria',
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `query filterExampleWithoutBestPractice {
    tax_assessor_v2(where: {
    situs_state: {_eq: "IL"},
    city: {_eq: "CHICAGO"},
    lot_size_sq_ft: {_gte: 5000, _lte: 30000},
    year_built: {_gte: 2010},
    })
  {
    tax_assessor_id
    state
    usa_owner_unmask_v2__tax_assessor_id{
      owner_name
    }
  }
}`,
  },
  {
    level: 3,
    component: 'SubHeading',
    value: 'Example - Parameterized Query',
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `query filterExampleWithBestPractice(
  $state: String!,
  $city:String!,
  $lot_size_min:bigquery_decimal,
  $lot_size_max:bigquery_decimal,
  $year_built:bigquery_decimal
) {
    tax_assessor_v2(where: {
    situs_state: {_eq: $state},
    city: {_eq: $city},
    lot_size_sq_ft: {_gte: $lot_size_min, _lte: $lot_size_max},
    year_built: {_gte: $year_built},

    })
  {
    tax_assessor_id

    state
    usa_owner_unmask_v2__tax_assessor_id{
      owner_name
    }
  }
},`,
    variables: `{
  "state":"IL",
  "city": "CHICAGO",
  "lot_size_min": 5000,
  "lot_size_max": 30000,
  "year_built":2010
}`,
  },
  {
    level: 2,
    component: 'SubHeading',
    value: 'Escaping Line Terminators',
  },
  {
    level: 3,
    component: 'Text',
    value: `Like white space, line terminators are used to improve the readability of your graphql queries. They may appear before or after any other token and have no significance to the semantic meaning of a GraphQL Document.`,
  },
  {
    level: 3,
    component: 'Text',
    value:
      'Like white space, line terminators are used to improve the readability of your graphql queries. They may appear before or after any other token and have no significance to the semantic meaning of a GraphQL Document.',
  },
  {
    level: 3,
    component: 'Text',
    value:
      'For more information see [[https://spec.graphql.org/June2018/#sec-Line-Terminators|GraphQL Specification]] for Line Terminators\n',
  },
  {
    level: 3,
    component: 'Text',
    value:
      'GraphQL requests are transmitted to the API as a string within a JSON object. Please be mindful that special characters like line terminators within a JSON string must be properly escaped. Failing to do so will produce a response error.',
  },
  {
    level: 3,
    component: 'Text',
    value: `When you write queries using a GraphQL client (such as GraphiQL, GraphQL Playground, etc), the client automatically escapes the new lines for you. There is a "Copy" button on the top of the GraphQL client which will automatically escape the new lines. If you copy/paste your query from your GraphQL client into code, be sure new lines are properly escaped. Some languages/environments will automatically do this for you. Others may require you to manually escape your new lines with "\\n".`,
  },
  {
    level: 2,
    component: 'SubHeading',
    value: 'String Encoding of Numeric Values',
  },
  {
    level: 3,
    component: 'Text',
    value:
      'By default, the Analytics API will string encode numeric values in the response.  When querying the Analytics API endpoint, the numeric value is wrapped in quotation marks, effectively casting the value as a string.',
  },
  {
    level: 3,
    component: 'Text',
    value:
      'The Cherre Analytics API allows you to provide a string value as input for a numeric field, and the graphql engine will automatically parse the string as a numeric value.',
  },
];

export default page2ImportantNotes;
