import last from 'lodash/last';

export const getId = (val = '') =>
  val
    .toLowerCase()
    .replace(/=|\\|\/|'|-/gi, '')
    .replace(/\./gi, '-')
    .replace(/\s+/gi, '-');

const getDataId = (val = '') =>
  val
    .toLowerCase()
    .replace(/=|\\|\/|'|\[|]|\?|\||:||\)|\(||\{|}/gi, '')
    .replace(/[!$,;’()*\n{}"~_<>]/gi, '')
    .replace(/\./gi, '-')
    .replace(/&/, '-')
    .replace(/\s+/gi, '-')
    .slice(0, 100);

export const getPageId = (sectionId) => {
  if (!sectionId) {
    return null;
  }

  return sectionId.split('--')[1];
};

export const patchElements = (elements, i) =>
  elements.map((element) => {
    return {
      ...element,
      id: `${getDataId(element.value)}--${i}`,
    };
  });

export const patchDataElements = (elements, i) =>
  elements.map((element) => {
    return {
      ...element,
      id: `${getDataId(element.value)}--${i}`,
    };
  });

export const elementsByPage = (elements) =>
  elements
    .reduce((memo, element) => {
      if (element.component === 'Page') {
        memo.push([element]);
      } else {
        last(memo).push(element);
      }

      return memo;
    }, [])
    .map((elements, i) => patchElements(elements, i));

export const elementsByPageData = (elements) =>
  elements
    .reduce((memo, element) => {
      if (element.component === 'Page') {
        memo.push([element]);
      } else {
        last(memo).push(element);
      }

      return memo;
    }, [])
    .map((elements, i) => patchDataElements(elements, i));

export const navigationElements = (elements) =>
  elementsByPage(elements)
    .flat()
    .filter((element) => ['Page', 'SubHeading'].includes(element.component));

export const elementData = (elements) => elementsByPageData(elements).flat();
