import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import decorate from '../../utils/decorate';
import decorators from '../../utils/decorators';
import { Typography } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';

const Text = ({ classes, children, id }) => {
  return (
    <div className={classes.root} id={id}>
      <React.Fragment>
        <Typography style={{ color: grey[800] }} variant={'body1'}>
          {decorate(children, decorators)}
        </Typography>
      </React.Fragment>
    </div>
  );
};

const styles = {
  root: {
    marginBottom: 20,
    lineHeight: 1.5,
    color: '#000000',
    whiteSpace: 'pre-wrap',
  },
};

export default withStyles(styles)(Text);
