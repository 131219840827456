import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const SubTitle = ({ classes, children, id }) => {
  return (
    <div id={id} className={classes.root}>
      <Typography variant='h5'>{children}</Typography>
    </div>
  );
};

const styles = (theme) => ({
  root: {
    fontSize: 20,
    color: '#212121',
    marginTop: 30,
    marginBottom: 5,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      fontWeight: 'bold',
    },
  },
});

export default withStyles(styles)(SubTitle);
