const page3GraphQLQueryOperations = [
  { level: 1, component: 'Page', value: `GraphQL Query Operations` },
  { level: 2, component: 'SubHeading', value: `Overview` },
  {
    level: 3,
    component: 'Text',
    value: `The following section describes the components of a GraphQL query that will be useful when building your API calls.`,
  },
  { level: 2, component: 'SubHeading', value: `Operators` },
  {
    level: 3,
    component: 'Text',
    value: `Operators are lowercase and prefixed with an underscore (“_”) character.`,
  },
  {
    level: 3,
    component: 'Table',
    value: `*_is_null*|Is null
*_eq*|Equals
*_neq*|Not equal
*_gt*|Greater than
*_gte*|Greater than or equal
*_lt*|Less than
*_lte*|Less than or equal
*_in*|Part of a list
*_nin*|Not part of list
*_like*|Matches case-sensitive pattern
*_ilike*|Matches case-insensitive pattern
*_nlike*|Does not match case-sensitive pattern
*_nilike*|Does not match case-insensitive pattern
*_similar* |Matches the pattern using the SQL standard's definition of a regular expression 
*_nsimilar*|Does not match pattern using the SQL standard's definition of a regular expression
*_or*|Matches either case
*_and*|Matches both cases
*_regex*|Matches a regular expression (case-sensitive)
*_iregex*|Matches a regular expression (case-insensitive)
*_nregex*|Does not match a regular expression (case-sensitive)
*_niregex*|Does not match a regular expression (case-isensitive)`,
  },
  {
    level: 3,
    component: 'Text',
    value: `Note: you can simplify *_and* expressions by passing the sub-expressions separated by a "," (i.e., multiple comma-separated criteria).  [[https://hasura.io/docs/latest/graphql/core/api-reference/graphql-api/query.html#andexp|see examples]]`,
  },
  { level: 2, component: 'SubHeading', value: `Filtering` },
  {
    level: 3,
    component: 'Text',
    value: `*It is important to note that filtering at different object levels (parent vs child objects) may produce different query results.* 

The examples below help illustrate these nuanced differences in a progression based on querying the tax_assessor and recorder objects:`,
  },
  {
    level: 2,
    component: 'Text',
    value: `SAMPLE 1:  Apply a filter at the Parent Level`,
  },
  {
    level: 3,
    component: 'Text',
    value: `When filtering on the parent object, you can expect the filter to only apply to the parent object.  Any related data from nested children objects will be unfiltered.

For example:
Give me all tax assessor records in a given zip code where the square footage is > 10,000 square feet.  For each qualifying tax assessor result, show me ALL related recorder data.`,
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `query parentFilters {
  tax_assessor_v2(
    where: {
      zip: {_eq: "10001"}, 
      gross_sq_ft: {_gt: 10000},
      tax_assessor_id: {_gte: 672273624}
    }
    order_by: {tax_assessor_id: asc}
    limit: 100
  ) {
    tax_assessor_id
    gross_sq_ft
    recorder_v2__tax_assessor_id {
      document_type_code
      document_amount
      document_recorded_date
    }
  }
}`,
  },
  {
    level: 2,
    component: 'Text',
    value: `SAMPLE 2:  Apply a filter at the Child Level`,
  },
  {
    level: 3,
    component: 'Text',
    value: `This example shows the same parent and child relationship, but with filters applied to the child object. With this filter, you can expect all of the records from tax_assessor to come back, but the related recorder data will only include entries that satisfy the 'document_type_code' filter criteria.

For example:
Give me all tax assessor records in a given zip code where the square footage is > 10,000 square feet.  For each qualifying tax assessor result, show me only the related recorder data where 'document_type_code' equals "DTWD".  If no recorder entries match that document type, then return an empty 'recorder_v2__tax_assessor_id[ ]' sequence.`,
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `query childFilters {
  tax_assessor_v2(
    where: {
      zip: {_eq: "10001"}, 
      gross_sq_ft: {_gt: 10000},
      tax_assessor_id: {_gte: 672273624} 
    }
    order_by: {tax_assessor_id: asc}
    limit: 100
  ) {
    tax_assessor_id
    gross_sq_ft
    recorder_v2__tax_assessor_id(where: {document_type_code: {_eq: "DTWD"}}) {
      document_type_code
      document_amount
      document_recorded_date
    }
  }
}`,
  },
  {
    level: 2,
    component: 'Text',
    value: `SAMPLE 3:  Apply a Child filter at the Parent Level`,
  },
  {
    level: 3,
    component: 'Text',
    value: `This example shows the same parent and child relationship, but with child filters applied to the parent object.

With this filter you can expect only tax_assessor_v2 results where the tax_assessor_id also has related recorder data that meets the criteria, however when the recorder data is returned, all recorder entries will be returned (ie, filters not applied to recorder results).

For example:
Give me all tax assessor records in a given zip code where the square footage is > 10,000 square feet AND the tax assessor record has one or more recorder entries where the 'document_type_code' equals "DTWD".  For each qualifying tax assessor result, show me ALL related recorder data.`,
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `query childFilterOnParentLevel {
  tax_assessor_v2(
    where: {
      zip: {_eq: "10001"},
      gross_sq_ft: {_gt: 10000},
      tax_assessor_id: {_gte: 672273624},
      recorder_v2__tax_assessor_id: {document_type_code: {_eq: "DTWD"}}
    }
    order_by: {tax_assessor_id: asc}
    limit: 100
  ) {
    tax_assessor_id
    gross_sq_ft
    recorder_v2__tax_assessor_id {
      document_type_code
      document_amount
    }
  }
}`,
  },
  {
    level: 2,
    component: 'Text',
    value: `SAMPLE 4:  Apply a filter at both the Child and Parent Levels`,
  },
  {
    level: 3,
    component: 'Text',
    value: `This example shows the same parent and child relationship, but with the same filter criteria applied at to both child and parent objects.

For example:
Give me all tax assessor records in a given zip code where the square footage is > 10,000 square feet AND the tax assessor record has one or more recorder entries where the 'document_type_code' equals "DTWD".  For each qualifying tax assessor result, show me only the related recorder data where 'document_type_code' equals "DTWD".`,
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `query filterOnParentAndChild {
  tax_assessor_v2(
    where: {
      zip: {_eq: "10001"},
      gross_sq_ft: {_gt: 10000},
      tax_assessor_id: {_gte: 672273624},
      recorder_v2__tax_assessor_id: {document_type_code: {_eq: "DTWD"}}
    }
    order_by: {tax_assessor_id: asc}
    limit: 100
  ) {
    tax_assessor_id
    gross_sq_ft
    recorder_v2__tax_assessor_id(
      where:{document_type_code:{_eq:"DTWD"}}
    ) {
      document_type_code
      document_amount
    }
  }
}`,
  },
  { level: 2, component: 'SubHeading', value: `Sorting` },
  {
    level: 3,
    component: 'Text',
    value: `Results from your query can be sorted by using the order_by argument. The order_by argument takes an array of parameters to allow sorting by multiple columns.`,
  },
  {
    level: 3,
    component: 'Text',
    value: `The sort order (ascending vs. descending) is set by specifying _asc or _desc after the column name in the order_by argument. Example:  name_desc`,
  },
  {
    level: 3,
    component: 'Text',
    value: `By default, null values are returned at the end of the results. Null values can be fetched first by adding _nulls_first after the sorting order. Example:  name_desc_nulls_first`,
  },
  {
    level: 3,
    component: 'Text',
    value: `The full set of options for ordering are below:`,
  },
  {
    level: 3,
    component: 'UnorderedList',
    value: `desc
desc_nulls_first
desc_nulls_last
asc 
asc_nulls_first
asc_nulls_last `,
  },
  { level: 2, component: 'SubHeading', value: `Distinct` },
  {
    level: 3,
    component: 'Text',
    value: `You can view distinct values from your query by using the distinct_on operator. `,
  },
  {
    level: 3,
    component: 'Text',
    value: `For example, if you wanted to see all of the distinct states in a table of counties in the US you could use this query (try this query with and without distinct_on).`,
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `{
  usa_county_boundary(distinct_on: state) {
    state
  }
}`,
  },
];

export default page3GraphQLQueryOperations;
