import page1TheBasics from 'src/products/api-doc/schema/coreapi-elements/page1-TheBasics';
import page2MakeYourFirstQuery from 'src/products/api-doc/schema/coreapi-elements/page2-MakeYourFirstQuery';
import page3GraphQLQueryOperations from 'src/products/api-doc/schema/coreapi-elements/page3-GraphQLQueryOperations';
import page4AvailableObjects from 'src/products/api-doc/schema/coreapi-elements/page4-AvailableObjects';
import page5PaginateQueryResults from 'src/products/api-doc/schema/coreapi-elements/page5-PaginateQueryResults';
import page6ObjectRelationships from 'src/products/api-doc/schema/coreapi-elements/page6-ObjectRelationships';
import page7GraphQLFunctions from 'src/products/api-doc/schema/coreapi-elements/page7-GraphQLFunctions';
import page8CherreStandardizationServices from 'src/products/api-doc/schema/coreapi-elements/page8-CherreStandardizationServices';
import page9SampleUseCases from 'src/products/api-doc/schema/coreapi-elements/page9-SampleUseCases';
import page10QueryThroughHTTP from 'src/products/api-doc/schema/coreapi-elements/page10-QueryThroughHTTP';
import page11ReleaseNotes from 'src/products/api-doc/schema/coreapi-elements/page11-ReleaseNotes';

const elements = [
  ...page1TheBasics,
  ...page2MakeYourFirstQuery,
  ...page3GraphQLQueryOperations,
  ...page4AvailableObjects,
  ...page5PaginateQueryResults,
  ...page6ObjectRelationships,
  ...page7GraphQLFunctions,
  ...page8CherreStandardizationServices,
  ...page9SampleUseCases,
  ...page10QueryThroughHTTP,
  ...page11ReleaseNotes,
];

export default elements;
// module.exports.elements = elements;
