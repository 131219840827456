const page9SampleUseCases = [
  { level: 1, component: 'Page', value: `Sample Use Cases` },
  { level: 2, component: 'SubHeading', value: `Overview` },
  {
    level: 3,
    component: 'Text',
    value: `In order to build a basic GraphQL query, you will need to include the following at a minimum:`,
  },
  {
    level: 3,
    component: 'OrderedList',
    value: `The name of the object you would like to pull data from. For example, {tax_assessor_v2} is the object used to store data about nationwide tax assessor data.
The fields within the given object that you would like to receive from the API call. For example, the tax bill amount and square footage for a given tax parcel.`,
  },
  {
    level: 2,
    component: 'SubHeading',
    value: `Sample 1 - Pulling Data by APN and Fips Code`,
  },
  {
    level: 3,
    component: 'Text',
    value: `A sample query to pull address, city, state, zip, assessed value, assessed year, and tax bill for a given apn and fips code within the tax_assessor object.`,
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `query apnFips {
  tax_assessor_v2(
    where: {
      assessor_parcel_number_raw: { _eq: "00450-0057" }
      fips_code: { _eq: "36047" }
    }
  ) {
    tax_assessor_id
    address
    city
    state
    zip
    tax_bill_amount
    assessed_value_total
    assessed_tax_year
  }
}`,
  },
  {
    level: 2,
    component: 'SubHeading',
    value: `Sample 2 - Adding Nested Recorder Object`,
  },
  {
    level: 3,
    component: 'Text',
    value: `This sample adds recorder data as nested objects to the tax_assessor object. This allows the user to dive deeper into the transactions for a lot. Note that queries deeper than 2 to 3 objects could become less performant depending on a number of factors within the data and query itself.`,
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `query taxId {
  tax_assessor_v2(where: { tax_assessor_id: { _eq: 208915440 } }) {
    tax_assessor_id
    tax_bill_amount
    gross_sq_ft
    recorder_v2__tax_assessor_id (order_by:{document_recorded_date:desc}) {
      document_amount
      document_recorded_date
      document_type_code
    }
  }
}`,
  },
  {
    level: 2,
    component: 'SubHeading',
    value: `Sample 3 - Filtering on Nested Objects`,
  },
  {
    level: 3,
    component: 'Text',
    value: `You are not required to return data about a nested object in order to apply a filter to that object. For example, if you wish to return tax assessor records with a certain owner, you do not need to return any data about the owner in order to filter on the owner name.`,
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `query ownerSearch {
  tax_assessor_v2(
    where: {
      tax_assessor_owner_v2__tax_assessor_id: {
        owner_name: { _eq: "WELLS FARGO" }
      }
    }
  ) {
    tax_assessor_id
    tax_assessor_owner_v2__tax_assessor_id(order_by: { owner_name: desc }) {
      owner_name
    }
  }
}`,
  },
  {
    level: 2,
    component: 'SubHeading',
    value: `Sample 4 - Neighborhood Search for Assessor Data`,
  },
  {
    level: 3,
    component: 'Text',
    value: `The following query returns tax assessor records within a given neighborhood.`,
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `query Neighborhood {
  usa_neighborhood_boundary_v2(where: { geography_name: { _eq: "NoMad" } }) {
    geography_name
    tax_assessor_v2_usa_neighborhood_boundary_v2__bridge {
      tax_assessor_v2__tax_assessor_id {
        tax_assessor_id
      }
    }
  }
}`,
  },
  {
    level: 2,
    component: 'SubHeading',
    value: `Sample 5 - Point-in-Polygon Search for Assessor Data`,
  },
  {
    level: 3,
    component: 'Text',
    value: `The following query allows the user to pass a latitude and longitude to return the tax_assessor object, and other objects connected to tax_assessor (e.g. tax_assessor_owner). The tax_assessor object is also connected to parcel_boundary, so the user has the ability to return the boundary geometry as well in the query below.`,
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `query taxPoint {
  tax_assessor_point_v2(args: { latitude: 40.723975, longitude: -73.983591 }) {
    address
    parcel_boundary_v2__tax_assessor_id {
      assessor_parcel_number
      geom
    }
  }
}`,
  },
  {
    level: 2,
    component: 'SubHeading',
    value: `Sample 6 - Bulk Search for Properties`,
  },
  {
    level: 3,
    component: 'Text',
    value: `The following query allows a user to search for large numbers of properties with a given set of characteristics.  The following fields can be used to order results in the assessor and recorder cardinal objects.`,
  },
  {
    level: 3,
    component: 'UnorderedList',
    value: `tax_assessor - assessor_last_updated 
recorder - last_updated_date`,
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `query City($previous_id: numeric!) {
  tax_assessor_v2(
    where: {
      city: { _eq: "CHICAGO" }
      state: { _eq: "IL" }
      gross_sq_ft: { _gt: 10000 }
      tax_assessor_id: { _lt: $previous_id }
    }
    order_by: { tax_assessor_id: desc }
    limit: 100
  ) {
    tax_assessor_id
  }
}`,
    variables: `{"previous_id": 1316127580}`,
  },
  {
    level: 3,
    component: 'Text',
    value: `If a user also wishes to return all of the recorder records associated with each tax assessor record, the tax_assessor_id from the previous call can be used within a script.`,
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `query recorderSearch {
  recorder_v2(
    where: { tax_assessor_id: { _eq: 208915440 } }
  ) {
    document_amount
    document_recorded_date
    document_type_code
  }
}`,
  },
];

export default page9SampleUseCases;
