import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connectAutoComplete } from 'react-instantsearch-dom';
import CustomHit from './CustomHit';
import AutoSuggest from 'react-autosuggest';

const ApiSearchAutoComplete = ({
  onSuggestionCleared,
  refine,
  currentRefinement,
  hits,
  onSuggestionSelected,
  placeholder = 'Search',
  onChange = undefined,
}) => {
  const [value, setValue] = useState(currentRefinement);

  const onInputChange = (_, { newValue }) => {
    if (!newValue) {
      onSuggestionCleared();
    }
    onChange?.(newValue);
    setValue(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    refine(value);
  };

  const onSuggestionsClearRequested = () => {
    refine();
  };

  const getSuggestionValue = (hit) => {
    return hit.name;
  };

  const renderSuggestion = (hit) => {
    return <CustomHit hit={hit} />;
  };

  const inputProps = {
    placeholder,
    onChange: onInputChange,
    value: value ? value : '',
  };

  return (
    <AutoSuggest
      suggestions={hits}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={onSuggestionSelected}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
    />
  );
};

ApiSearchAutoComplete.propTypes = {
  hits: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentRefinement: PropTypes.string.isRequired,
  refine: PropTypes.func.isRequired,
  onSuggestionSelected: PropTypes.func.isRequired,
  onSuggestionCleared: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default connectAutoComplete(ApiSearchAutoComplete);
