import Typography from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';
import { Highlight } from 'react-instantsearch-dom';
import jump from 'src/utils/jump';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

const PrimaryText = ({ hit }) => {
  return (
    <Typography style={{ color: grey[900] }}>
      <Highlight attribute='value' hit={hit} tagName='mark' />
    </Typography>
  );
};

const CustomHit = ({ hit }) => {
  const dispatch = useDispatch();
  const onSectionClick = () => {
    dispatch(push(`/core-api#${hit.id}`));
    jump(`#${hit.id}`, {
      container: '#api-doc-body',
      offset: 158,
      duration: 200,
    });
  };

  return (
    <ListItem alignItems='flex-start' onClick={onSectionClick}>
      <ListItemText primary={<PrimaryText hit={hit} />} />
    </ListItem>
  );
};

export default CustomHit;
