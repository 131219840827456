import React from 'react';
import Typography from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';
import NavigationSubSections from '../NavigationSubSections';
import { withStyles } from '@material-ui/core/styles';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { primaryColor } from 'src/const/theme';

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    margin: 0,
    '&:hover': {
      background: '#f5f5f5',
    },
  },
})(MuiAccordionSummary);

const NavigationContentExpansionWithSubSections = ({
  section,
  active,
  onSectionClick,
  activeSubPageId,
}) => {
  return (
    <React.Fragment>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel-content'
          id='panel-header'
        >
          <Typography
            style={{
              color: active ? primaryColor.main : grey[800],
              fontWeight: 600,
              paddingLeft: 5,
            }}
            onClick={(event) => {
              event.stopPropagation();
              onSectionClick(section);
            }}
            onFocus={(event) => event.stopPropagation()}
          >
            {section.title}
          </Typography>
        </AccordionSummary>
        <NavigationSubSections
          subSections={section.subSections}
          activeSubPageId={activeSubPageId}
          onSectionClick={onSectionClick}
        />
      </Accordion>
    </React.Fragment>
  );
};

export default NavigationContentExpansionWithSubSections;
