import React, { useContext, useEffect, useMemo } from 'react';
import jump from 'src/utils/jump';
import { withStyles } from '@material-ui/core/styles';

import { elementsByPage } from '../../schema/getElements';
import { Context } from '../../index';
import { useDocumentationElements } from 'src/hooks/useDocumentationElements';

const components = {
  Page: require('./Title').default,
  Text: require('./Text').default,
  SubHeading: require('./SubHeading').default,
  OrderedList: require('./OrderedList').default,
  UnorderedList: require('./UnorderedList').default,
  Code: require('./Code').default,
  Table: require('./Table').default,
  Image: require('./Image').default,
  GraphiQL: require('./GraphiQL').default,
};

const Body = ({ classes }) => {
  const { sectionId, makeSectionActive, documentationType } =
    useContext(Context);

  const pageElements = useDocumentationElements(documentationType);

  const elements = useMemo(() => elementsByPage(pageElements), [pageElements]);

  useEffect(() => {
    if (!sectionId) {
      return;
    }

    makeSectionActive(sectionId);
    jump(`#${sectionId}`, {
      container: '#api-doc-body',
      offset: 158,
      duration: 200,
    });
  }, []);

  return (
    <div>
      {elements.map((elements, index) => {
        return (
          <div key={index} className={classes.root}>
            {Object.values(elements).map((element, index) => {
              const Component = components[element.component];

              if (!Component) {
                console.error(`Missing component "${element.component}"`);
                return null;
              }

              if (element.component === 'GraphiQL') {
                return (
                  <Component
                    key={`${index}-${element.id}`}
                    id={element.id}
                    {...element}
                  />
                );
              }
              return (
                <Component
                  key={`${index}-${element.id}`}
                  id={element.id}
                  mode={element.mode}
                >
                  {element.value}
                </Component>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

const styles = (theme) => ({
  root: {
    boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.2)',
    borderRadius: 3,
    backgroundColor: '#ffffff',
    padding: '26px 21px',
    lineHeight: 1.5,
    [theme.breakpoints.down('sm')]: {
      padding: '21px 17px',
    },
    marginBottom: 16,
  },
});

export default withStyles(styles)(Body);
