const page1GettingStarted = [
  { level: 1, component: 'Page', value: `Getting Started` },
  {
    level: 3,
    component: 'Text',
    value:
      'Cherre’s Analytics API is optimized for handling large data sets, and the ability to pull up to 25,000 records per query depending on joins and filters. This endpoint also supports aggregate functions which can easily calculate important metrics such as the total number of items in a list, the sum of a set of values, or the average of a field.  Joining of objects in the GraphQL query are enabled, but with less flexibility in the number of nested joins making this endpoint ideal for powering visualization tools, a data warehouse, or calculations.',
  },
  { level: 2, component: 'SubHeading', value: `GraphQL` },
  {
    level: 3,
    component: 'Text',
    value:
      'GraphQL is a query language for APIs.  It provides a complete and understandable description of the data in your API and gives you the power to ask for exactly which fields and which data is needed for your use case. This makes it easier to maintain your integration over time.',
  },
  {
    level: 3,
    component: 'Text',
    value:
      'If you are new to GraphQL we might recommend a few resources from the GraphQL community to help you get started',
  },
  {
    level: 3,
    component: 'UnorderedList',
    value: `
    [[http://graphql.org/learn/ Introduction to GraphQL]]
    [[https://www.howtographql.com/ How to GraphQL]]
    `,
  },
  { level: 2, component: 'SubHeading', value: `API Endpoint` },
  {
    level: 3,
    component: 'Text',
    value:
      'The Cherre Analytics API can be accessed at [[https://analytics-graphql.cherre.com/graphql]].',
  },
  {
    level: 2,
    component: 'SubHeading',
    value: `API Keys`,
  },
  {
    level: 3,
    component: 'Text',
    value: 'API Keys can be generated on the API Keys page.',
  },
];

export default page1GettingStarted;
