import React from 'react';
import codemirror from 'codemirror';

import 'codemirror/lib/codemirror.css';
import 'codemirror-graphql/mode';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/shell/shell';
import 'codemirror/mode/python/python';
import 'codemirror/addon/display/autorefresh';

const Component = ({ children, id, mode = null }) => {
  const ref = React.useRef();

  if (!mode) {
    let isJSON = true;

    try {
      JSON.parse(children);
    } catch (e) {
      isJSON = false;
    }

    let isQuery = true;

    if (!isJSON) {
      isQuery = children[0] === 'q';
    }

    if (isQuery) {
      mode = 'graphql';
    } else if (isJSON) {
      mode = 'javascript';
    }
  }

  React.useEffect(() => {
    codemirror.fromTextArea(ref.current, {
      theme: 'default',
      readOnly: true,
      mode,
      lineNumbers: true,
      autoRefresh: true,
    });
  }, []);

  return (
    <div style={{ margin: '0 auto 20px' }} id={id}>
      <textarea defaultValue={children} ref={ref} />
    </div>
  );
};

export default Component;
