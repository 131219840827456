import React, { useEffect } from 'react';

import MobileNavigationLayout from './components/Layouts/MobileNavigationLayout';
import BasicLayout from './components/Layouts/BasicLayout';
import { getPageId } from './schema/getElements';
import { getSchemaByIntrospectionQuery } from 'src/products/api-doc/redux/introspectionSchema/actions';
import { connect } from 'react-redux';
import { RouteProps } from 'react-router';

type ApiDocContext = {
  toggleNavigation?: React.Dispatch<React.SetStateAction<boolean>>;
  sectionId?: string;
  activePageId?: string;
  activeSubPageId?: string;
  makeSectionActive?: (sectionId: string) => void;
  documentationType: 'coreApi' | 'analyticsApi' | 'pythonSnippets';
};

export const Context = React.createContext<ApiDocContext>({
  documentationType: 'coreApi',
});

type ApiDocProps = {
  getSchemaByIntrospectionQuery: typeof getSchemaByIntrospectionQuery;
} & RouteProps;

const ApiDoc: React.FC<ApiDocProps> = ({
  getSchemaByIntrospectionQuery,
  location,
}) => {
  const [isNavigationOpen, toggleNavigation] = React.useState(false);
  const [activePageId, makePageActive] = React.useState<string>();
  const [activeSubPageId, makeSubPageActive] = React.useState<string>();

  const sectionId = location?.hash.replace('#', '') ?? undefined;

  let documentationType;
  if (location?.pathname?.includes('core-api')) {
    documentationType = 'coreApi';
  } else if (location?.pathname?.includes('analytics-api')) {
    documentationType = 'analyticsApi';
  } else if (location?.pathname?.includes('python-snippets')) {
    documentationType = 'pythonSnippets';
  } else {
    throw new Error('No valid string was specified for `documentationType`');
  }

  useEffect(() => {
    if (documentationType === 'pythonSnippets') {
      return;
    }
    getSchemaByIntrospectionQuery(documentationType);
  }, [location.pathname]);

  const lastTime = React.useRef(Date.now());

  const makeSectionActive = React.useCallback(
    (sectionId: string) => {
      const pageId = getPageId(sectionId);

      if (sectionId !== activeSubPageId) {
        window.analytics?.track(
          'Track number of views on each webpage subsection',
          {
            sectionId,
          }
        );
        const now = Date.now();
        const ms = now - lastTime.current;
        window.analytics?.track(
          'Session time per user on each webpage subsection',
          { sectionId: activeSubPageId, ms }
        );
        lastTime.current = now;
      }

      makePageActive(pageId);
      makeSubPageActive(sectionId);
    },
    [activeSubPageId]
  );

  return (
    <Context.Provider
      value={{
        toggleNavigation,
        sectionId,
        activePageId,
        activeSubPageId,
        makeSectionActive,
        documentationType,
      }}
    >
      {isNavigationOpen ? <MobileNavigationLayout /> : <BasicLayout />}
    </Context.Provider>
  );
};

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, { getSchemaByIntrospectionQuery })(
  ApiDoc
);
