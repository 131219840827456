const page5CherreAddressStandardization = [
  { level: 1, component: 'Page', value: `Cherre Address Standardization` },
  { level: 2, component: 'SubHeading', value: 'Address Standardization' },
  {
    level: 3,
    component: 'Text',
    value:
      'Cherre provides an address standardization service that allows passing an address string to the API and returns a standardized version of the address that can be used for querying other objects by address.',
  },
  {
    level: 3,
    component: 'Text',
    value:
      'A sample API call for the service is below where an address string is accepted within the “address” input parameter. The address service will accept any combination of address and zip code or city and state.',
  },
  {
    level: 3,
    component: 'SubHeading',
    value: 'Example - Address Standardization',
  },
  {
    level: 4,
    component: 'GraphiQL',
    value: `query addressStandardizationSample{
  address(address: "8161 Seaton Pl, MONTGOMERY Al") {
    address__cherre_address {
      display_address
    }
  }
}`,
  },
  {
    level: 2,
    component: 'SubHeading',
    value: 'Utilizing the Standardized Address',
  },
  {
    level: 3,
    component: 'Text',
    value:
      'We will use the standardized address returned from the address service to find the associated tax assessor, recorder and point of interest values for this address. If your organization has other datasets connected by address through Cherre, the other datasets would also be accessible from this endpoint.',
  },
  {
    level: 3,
    component: 'SubHeading',
    value: 'Example - Joining Using a Standardized Address',
  },
  {
    level: 4,
    component: 'GraphiQL',
    value: `query addressTaxAssessor {
  cherre_address(
    where: {display_address: {_eq: "8161 SEATON PLACE, MONTGOMERY, AL 36116"}}
  ) {
    display_address
    tax_assessor_v2__property_address {
      tax_assessor_id
      assessed_value_total
    }
  }
}`,
  },
  {
    level: 3,
    component: 'Text',
    value:
      'The standardization and join to other tables can also be done using a single query as shown below.',
  },
  {
    level: 3,
    component: 'SubHeading',
    value: 'Example - Combined Address Standardization and Join',
  },
  {
    level: 4,
    component: 'GraphiQL',
    value: `query addressStandardizationSample{
  address(address: "8161 Seaton Pl, MONTGOMERY Al") {
    cherre_address__address{
      display_address
      tax_assessor_v2__property_address{
        tax_assessor_id
        assessed_value_total
      }
    }
  }
}`,
  },
  {
    level: 2,
    component: 'SubHeading',
    value: 'Displaying a Standardized Address',
  },
  {
    level: 3,
    component: 'Text',
    value:
      'cherre_address is also the place to find the standardized form of an address from any dataset.',
  },
  {
    level: 3,
    component: 'SubHeading',
    value: 'Example - Get Standardized Addresses For Parcel',
  },
  {
    level: 4,
    component: 'GraphiQL',
    value: `query  {
  tax_assessor_v2(where:{tax_assessor_id:{_eq:"997887940"}}) {
    tax_assessor_id
    cherre_address__property_address {
      display_address
    }
  }
}`,
  },
  {
    level: 3,
    component: 'SubHeading',
    text: 'Example - Get Standardized Addresses for Point of Interest',
  },
  {
    level: 4,
    component: 'GraphiQL',
    value: `query standardizedPOIAddress {
  usa_points_of_interest(limit: 100, where: {business_name: {_eq: "WALMART"}}) {
    business_name
    cherre_address__address {
      display_address
    }
  }
}`,
  },
];

export default page5CherreAddressStandardization;
