import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const NavigationSectionItem = ({ classes, children, onClick, active }) => {
  return (
    <div
      style={{ background: active ? '#f5f5f5' : null }}
      onClick={onClick}
      className={classes.root}
    >
      {children}
    </div>
  );
};

const styles = {
  root: {
    width: '100%',
    paddingBottom: 10,
    paddingTop: 10,
    paddingLeft: 41,
    paddingRight: 5,
    cursor: 'pointer',
    color: '#757575',
    '&:hover': {
      background: '#f5f5f5',
    },
  },
};

export default withStyles(styles)(NavigationSectionItem);
