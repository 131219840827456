import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';

import decorate from '../../utils/decorate';
import decorators from '../../utils/decorators';

const List = ({ classes, children, id }) => {
  const items = children.trim().split('\n');

  return (
    <div className={classes.root} id={id}>
      <Typography component='div' style={{ color: grey[800] }}>
        <ol>
          {items
            .map((item, index) => {
              if (!item) {
                return null;
              }
              return (
                <li key={index} className={classes.item}>
                  {decorate(item, decorators)}
                </li>
              );
            })
            .filter(Boolean)}
        </ol>
      </Typography>
    </div>
  );
};

const styles = {
  root: {
    color: '#000',
    marginBottom: 20,
  },
  item: {
    marginBottom: 10,
  },
};

export default withStyles(styles)(List);
