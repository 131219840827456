import page01SampleAddressQuery from 'src/products/api-doc/schema/pythonSnippets-elements/page01-SampleAddressQuery';
import page02SampleAssessorLoaderQuery from 'src/products/api-doc/schema/pythonSnippets-elements/page02-SampleAssessorLoaderQuery';
import page03SampleJoinedTableQuery from 'src/products/api-doc/schema/pythonSnippets-elements/page03-SampleJoinedTableQuery';
import page04SampleLatLongQuery from 'src/products/api-doc/schema/pythonSnippets-elements/page04-SampleLatLongQuery';

const elements = [
  ...page01SampleAddressQuery,
  ...page02SampleAssessorLoaderQuery,
  ...page03SampleJoinedTableQuery,
  ...page04SampleLatLongQuery,
];

export default elements;
