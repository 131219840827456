import React from 'react';
import { Link } from 'react-router-dom';
import { primaryColor } from 'src/const/theme';
import Code from '../components/Body/Code';
import Image from '../components/Body/Image';

export const internalLink = {
  regexp: /\[([\s\S.]+?)\]/g,
  format: (match) => (
    <Link color='secondary' key={Math.random()} to={match.split('|')[0]}>
      {match.split('|')[1] || match.split('|')[0]}
    </Link>
  ),
};

export const externalLink = {
  regexp: /\[\[([\s\S.]+?)\]\]/g,
  format: (match) => (
    <a
      style={{
        color: primaryColor.main,
      }}
      target='_blank'
      key={Math.random()}
      href={match.split('|')[0]}
    >
      {match.split('|')[1] || match.split('|')[0]}
    </a>
  ),
};

export const bold = {
  regexp: /\*([\s\S.]+?)\*/g,
  format: (match) => <b key={Math.random()}>{match}</b>,
};

export const image = {
  regexp: /\[\[\[([\s\S.]+?)\]\]\]/g,
  format: (match) => (
    <div key={Math.random()} style={{ margin: '20px 0' }}>
      <Image>{match}</Image>
    </div>
  ),
};

export const code = {
  regexp: /~~~([\s\S.]+?)~~~/g,
  format: (match) => {
    return <Code key={Math.random()}>{match.trim()}</Code>;
  },
};

export default [code, image, externalLink, internalLink, bold];
