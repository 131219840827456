const page04SampleLatLongQuery = [
  { level: 1, component: 'Page', value: `Sample Lat Long Query` },
  {
    level: 3,
    component: 'Text',
    value: `The code snippet demonstrates how to query an specific Lat Long point using Cherre’s GraphQL API to retrieve the information of the property related to those coordinates`,
  },
  { level: 2, component: 'SubHeading', value: `Authorization` },
  {
    level: 3,
    component: 'Text',
    value: `The script uses an API token to authenticate requests. This token is included in the request headers for secure access to the API.`,
  },
  { level: 2, component: 'SubHeading', value: `Query` },
  {
    level: 3,
    component: 'Text',
    value: `The example requests information for a specified location (39.2583, -84.2860). The query is structured to return:
	•	tax_assessor_id: The tax assessor identifier, nested under tax_assessor_v2__property_address.
	•	address: Address of the property related to that location.`,
  },
  {
    level: 2,
    component: 'SubHeading',
    value: `Response`,
  },
  {
    level: 3,
    component: 'Text',
    value: `If the response code indicates success (200), the script parses and formats the JSON output for easy readability. If an error occurs, an exception is raised with the response’s status code and message. `,
  },
  {
    level: 3,
    component: 'Code',
    mode: 'python',
    value: `
import json
import requests

# User credentials
headers = {'Authorization': f'Bearer {token}'}
# API endpoint URL
url = 'https://graphql.cherre.com/graphql'

# Query by latitude and longitude
def query_latlong(latitude, longitude):
    query = f"""
    query {{
        tax_assessor_point_v2(args: {{latitude: {latitude}, longitude: {longitude}}}) {{
            tax_assessor_id
            address
            bed_count
            bath_count
        }}
    }}
    """
    response = requests.post(url, headers=headers, json={'query': query})
    if response.status_code == 200:
        return response.json()
    else:
        raise Exception(f"Query failed with status code {response.status_code} and message {response.text}")

try:
    result_latlong = query_latlong(39.2583, -84.2860)
    print("LatLong Query Result:", result_latlong)
except Exception as e:
    print(e)
    `,
  },
];

export default page04SampleLatLongQuery;
