const page03SampleJoinedTableQuery = [
  { level: 1, component: 'Page', value: `Sample Joined Table Query` },
  {
    level: 3,
    component: 'Text',
    value: `The code snippet demonstrates how to query an address using Cherre’s GraphQL API to retrieve related property details from several tables in the same requests.`,
  },
  { level: 2, component: 'SubHeading', value: `Authorization` },
  {
    level: 3,
    component: 'Text',
    value: `The script uses an API token to authenticate requests. This token is included in the request headers for secure access to the API.`,
  },
  { level: 2, component: 'SubHeading', value: `Query` },
  {
    level: 3,
    component: 'Text',
    value: `The Query variable holds a GraphQL query that requests information for a specified address ("10 Trenton Sq, Cleveland, OH 44143"). The query is structured to return:
	•	display_address: A standardized version of the address.
	•   tax_assessor_v2__property_address: Object with information regarding the property from several tables at once`,
  },
  {
    level: 2,
    component: 'SubHeading',
    value: `Response`,
  },
  {
    level: 3,
    component: 'Text',
    value: `If the response code indicates success (200), the script parses and formats the JSON output for easy readability. If an error occurs, an exception is raised with the response’s status code and message. `,
  },
  {
    level: 3,
    component: 'Code',
    mode: 'python',
    value: `
import json
import requests

# User credentials
headers = {'Authorization': f'Bearer {token}'}
# API endpoint URL
url = 'https://graphql.cherre.com/graphql'

query = """
{
  address(address: "10 Trenton Sq, Cleveland, OH 44143") {
    cherre_address__address {
      display_address
      tax_assessor_v2__property_address {
        bed_count
        bath_count
        tax_assessor_id
        property_use_code_mapped
        property_use_standardized_code
        last_sale_date
        last_sale_amount
        usa_avm_v2__tax_assessor_id(order_by:{valuation_date:desc}limit:3){
          estimated_value_amount
          valuation_date
        }
        usa_census_tract_boundary__geo_id_pk {
          usa_demographics_v2__geography_code(order_by: {vintage: desc}, limit: 1) {
            crime_total_risk
            average_household_income
          }
        }
        tax_assessor_v2_usa_school_boundary_v2__bridge {
          usa_school_boundaries_v2__geography_id {
            district_name
            usa_schools_v2__institution_id {
              institution_name
              test_rating
            }
          }
        }
      }
    }
  }
}

"""

response = requests.post(url, headers=headers, json={'query': query})

if response.status_code == 200:
    output = response.json()
    print(json.dumps(output, indent=2))
else:
    raise Exception(f"Query failed to run by returning code of {response.status_code}. {response.text}")
    `,
  },
];

export default page03SampleJoinedTableQuery;
