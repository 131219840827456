import React from 'react';
import jump from 'src/utils/jump';

import NavigationSections from './NavigationSections';

import Button from '../Toolbar/Button';
import { Context } from '../../index';

import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import CloseIcon from '@material-ui/icons/Close';

import { navigationElements } from '../../schema/getElements';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useDocumentationElements } from 'src/hooks/useDocumentationElements';

const transformSections = (elements) => {
  return elements.reduce((memo, e) => {
    if (e.component === 'Page') {
      memo.push({
        title: e.value,
        id: e.id,
        subSections: [],
      });
    } else {
      memo[memo.length - 1].subSections.push({
        title: e.value,
        id: e.id,
      });
    }

    return memo;
  }, []);
};

const Navigation = ({ classes }) => {
  const {
    toggleNavigation,
    activePageId,
    activeSubPageId,
    makeSectionActive,
    documentationType,
  } = React.useContext(Context);
  const pageElements = useDocumentationElements(documentationType);

  const sections = transformSections(navigationElements(pageElements));

  const dispatch = useDispatch();

  const onSectionClick = (section) => {
    toggleNavigation(false);
    if (documentationType === 'analyticsApi') {
      dispatch(push(`/analytics-api#${section.id}`));
    } else if (documentationType === 'pythonSnippets') {
      dispatch(push(`/python-snippets#${section.id}`));
    } else {
      dispatch(push(`/core-api#${section.id}`));
    }

    jump(`#${section.id}`, {
      container: '#api-doc-body',
      offset: 158,
      duration: 200,
    });

    setTimeout(() => {
      makeSectionActive(section.id);
    }, 350);
  };

  return (
    <div className={classes.root}>
      <Hidden mdUp>
        <Button
          onClick={() => toggleNavigation(false)}
          style={{ position: 'absolute', top: 20, right: 20 }}
        >
          <CloseIcon />
        </Button>
      </Hidden>
      <NavigationSections
        sections={sections}
        activePageId={activePageId}
        onSectionClick={onSectionClick}
        activeSubPageId={activeSubPageId}
      />
    </div>
  );
};

const styles = {
  root: {
    borderRadius: 3,
    padding: '26px 0',
    backgroundColor: '#ffffff',
    overflow: 'auto',
    position: 'relative',
  },
};

export default withStyles(styles)(Navigation);
