const page7Pagination = [
  { level: 1, component: 'Page', value: `Pagination` },
  {
    level: 3,
    component: 'Text',
    value:
      'Even with significantly larger row limits, many queries will require pagination in order to return all matching records.',
  },
  {
    level: 3,
    component: 'Text',
    value:
      'In order to paginate through records within an object, the primary key of the object can be used for ordering and filtering. This allows you to iterate through a large dataset with a simple sequence as outlined below:',
  },
  {
    level: 3,
    component: 'OrderedList',
    value: `Identify a starting record and pull the first page of results.
    Iterate through the results, saving the id from the last record.  This value will be passed to your next query.
    Repeat Step 2 until your AdditionalPages query returns 0 records.`,
  },
  {
    level: 2,
    component: 'SubHeading',
    value: 'Example - Getting the First Page',
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `query FirstPage($city: String!,$state: String!) {
  tax_assessor_v2(
    where: {
    city:{_eq:$city},state:{_eq:$state}
  }
  order_by: { tax_assessor_id: asc }
  limit: 10000
) {
    tax_assessor_id
    bed_count
    bath_count
    gross_sq_ft
  }
}`,
    variables: `{
  "city":"CHICAGO",
  "state":"IL"
}`,
  },
  {
    level: 2,
    component: 'SubHeading',
    value: 'Example - Getting the Subsequent Pages',
  },
  {
    level: 3,
    component: 'Text',
    value: `Pulling the subsequent pages from tax_assessor for a given city and state.
Here we use the last tax_assessor_id fetched from the initial load as a parameter ($previous_id) to fetch the next set of results
`,
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `query AdditionalPages($city: String!, $state: String!, $previous_id: bigquery_decimal!) {
  tax_assessor_v2(where: {city: {_eq: $city}, state: {_eq: $state}, tax_assessor_id: {_gt: $previous_id}}, order_by: {tax_assessor_id: asc}, limit: 10000) {
    tax_assessor_id
    bed_count
    bath_count
    gross_sq_ft
  }
}`,
    variables: `{
  "city":"CHICAGO",
  "state":"IL",
  "previous_id":735256
  }`,
  },
  {
    level: 2,
    component: 'SubHeading',
    value: 'Incremental Load',
  },
  {
    level: 3,
    component: 'Text',
    value: `If you have previously pulled data from tax_assessor and only need to pull the data that has been added since the last pull.`,
  },
  {
    level: 3,
    component: 'Text',
    value: `Pulling tax_assessor records that are new since Jan 1, 2023 and greater than the last tax_assessor_id pulled from previous loads.`,
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `query PaginateNewTaxAssessorRecords( $previous_id: bigquery_decimal!, $updated_at: Date!) {
  tax_assessor_v2(
    where: {
    tax_assessor_id: { _gt: $previous_id }
    data_publish_date:{_gt: $updated_at}
  }
  order_by: { tax_assessor_id: asc }
  limit: 10000
) {
    tax_assessor_id
    bed_count
    bath_count
    gross_sq_ft
    data_publish_date
  }
}`,
    variables: `{
  "previous_id": "18468" ,
  "updated_at": "2023-01-01"
}`,
  },
];

export default page7Pagination;
