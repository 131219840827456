import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const NavigationSection = ({ classes, children }) => {
  return <div className={classes.root}>{children}</div>;
};

const styles = {
  root: {},
};

export default withStyles(styles)(NavigationSection);
