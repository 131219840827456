const page02SampleAssessorLoaderQuery = [
  { level: 1, component: 'Page', value: `Sample Assessor Loader Query` },
  {
    level: 3,
    component: 'Text',
    value: `The code snippet demonstrates how to query Cherre’s GraphQL API using pagination to retrieve data in chunks of 100 records, then builds a Pandas dataframe with it to be worked on further`,
  },
  { level: 2, component: 'SubHeading', value: `Authorization` },
  {
    level: 3,
    component: 'Text',
    value: `The script uses an API token to authenticate requests. This token is included in the request headers for secure access to the API.`,
  },
  { level: 2, component: 'SubHeading', value: `Query` },
  {
    level: 3,
    component: 'Text',
    value: `The build_query function holds a GraphQL query that requests information for a specified state ("NJ"), city ("Wayne") and property use code ("44"). The query will return several records with each one containing:
	•	tax_assessor_id: The tax assessor identifier, present in tax_assessor_v2.
	•	gross_sq_ft: Gross square footage of the property.
	•	year_built: Year property was built.
    `,
  },
  {
    level: 2,
    component: 'SubHeading',
    value: `Response`,
  },
  {
    level: 3,
    component: 'Text',
    value: `If an error occurs during any of the requests there will be a message to show the error message and the code will stop, but the error don't raise the exception`,
  },
  {
    level: 3,
    component: 'Code',
    mode: 'python',
    value: `
import json
import requests
import time
import pandas as pd

# User credentials
headers = {'Authorization': f'Bearer {token}'}
# API endpoint URL
url = 'https://graphql.cherre.com/graphql'

def run_query(query):
    response = requests.post(url, headers=headers, json={'query': query})
    if response.status_code == 200:
        return response.json()
    else:
        raise Exception(f"Query failed with code {response.status_code}: {response.text}")

def build_query(last_id=None):
    """Build the query with dynamic pagination."""
    where_clause = """
        where: {
            state: {_eq: "NJ"},
            city: {_eq: "WAYNE"},
            property_use_code_mapped: {_eq: "44"}
        """
    if last_id:
        where_clause += f', tax_assessor_id: {{_gt: "{last_id}"}}'  # Use _gt for descending pagination
    where_clause += "}"
    
    query = f"""
    {{
      tax_assessor_v2(
        {where_clause}
        order_by: {{tax_assessor_id: desc}}
        limit: 100
      ) {{
        tax_assessor_id
        gross_sq_ft
        year_built
      }}
    }}
    """
    return query

def fetch_data():
    """Fetch all records with pagination."""
    all_results = []
    last_id = None  # Tracks the last tax_assessor_id

    while True:
        query = build_query(last_id)
        print(f"Running query with last_id: {last_id}")  # Debug print

        try:
            data = run_query(query)
            print(json.dumps(data, indent=2))  # Print raw response for inspection
        except Exception as e:
            print(f"Error occurred: {e}")
            break

        # Extract records from the response
        records = data.get("data", {}).get("tax_assessor_v2", [])
        if not records:
            print("No more records found.")  # Debug print
            break  # Stop if no records are returned

        # Add records to the result list
        all_results.extend(records)

        # Stop if fewer than 100 records are returned
        if len(records) < 100:
            print("Less than 100 records, stopping.")  # Debug print
            break

        # Update last_id to the smallest tax_assessor_id for next query
        last_id = records[-1]["tax_assessor_id"]

        # Sleep to avoid hitting API limits
        time.sleep(0.1)
    return all_results

# Execute and display the data
try:
    data = fetch_data()
    # Flatten and convert to DataFrame
    flat_data = [
        {
            "tax_assessor_id": record["tax_assessor_id"],
            "gross_sq_ft": record["gross_sq_ft"],
            "year_built": record["year_built"],
        }
        for record in data
    ]
    df = pd.DataFrame(flat_data)
except Exception as e:
    print(f"Error occurred: {e}")
    `,
  },
];

export default page02SampleAssessorLoaderQuery;
