import React from 'react';
import { getPageId } from 'src/products/api-doc/schema/getElements';
import NavigationSection from './NavigationSection';
import NavigationSectionContent from './NavigationSectionContent';

const NavigationSections = ({
  sections,
  activePageId,
  onSectionClick,
  activeSubPageId,
}) => {
  return sections.map((section, index) => {
    const active = getPageId(section.id) === activePageId;
    return (
      <NavigationSection key={index}>
        <NavigationSectionContent
          active={active}
          section={section}
          onSectionClick={onSectionClick}
          activeSubPageId={activeSubPageId}
        />
      </NavigationSection>
    );
  });
};

export default NavigationSections;
