import React from 'react';
import NavigationContentTitle from './NavigationContentTitle';
import NavigationContentExpansionWithSubSections from './NavigationContentExpansionWithSubSections';

const NavigationSectionContent = (props) => {
  const { subSections } = props.section;
  if (!subSections.length) {
    return <NavigationContentTitle {...props} />;
  }
  return <NavigationContentExpansionWithSubSections {...props} />;
};

export default NavigationSectionContent;
