import page1GettingStarted from 'src/products/api-doc/schema/analyticsapi-elements/page1-GettingStarted';
import page2ImportantNotes from 'src/products/api-doc/schema/analyticsapi-elements/page2-ImportantNotes';
import page3GraphQLQueries from 'src/products/api-doc/schema/analyticsapi-elements/page3-GraphQLQueries';
import page4GraphQLArguments from 'src/products/api-doc/schema/analyticsapi-elements/page4-GraphQLArguments';
import page5CherreAddressStandardization from 'src/products/api-doc/schema/analyticsapi-elements/page5-CherreAddressStandardization';
import page7Pagination from 'src/products/api-doc/schema/analyticsapi-elements/page7-Pagination';

const elements = [
  ...page1GettingStarted,
  ...page2ImportantNotes,
  ...page3GraphQLQueries,
  ...page4GraphQLArguments,
  ...page5CherreAddressStandardization,
  ...page7Pagination,
];

export default elements;
// module.exports.elements = elements;
