import { GET_INTROSPECTION_QUERY_SUCCESS } from './types';
import { buildClientSchema } from 'graphql';

const initialState = {
  schema: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_INTROSPECTION_QUERY_SUCCESS: {
      return {
        schema: buildClientSchema(action.data),
      };
    }
    default: {
      return state;
    }
  }
};
