import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const NavigationSectionTitle = ({ classes, children, onClick, active }) => {
  return (
    <div
      style={{ fontWeight: active ? 'bold' : 'normal' }}
      onClick={onClick}
      className={classes.root}
    >
      {children}
    </div>
  );
};

const styles = {
  root: {
    paddingBottom: 15,
    paddingTop: 15,
    paddingLeft: 21,
    color: '#000000',
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      background: '#f5f5f5',
    },
  },
};

export default withStyles(styles)(NavigationSectionTitle);
