const page11ReleaseNotes = [
  { level: 1, component: 'Page', value: `Release Notes` },
  { level: 2, component: 'SubHeading', value: `Version 2.19` },
  {
    level: 3,
    component: 'Text',
    value: `In release 2.19, the recorder_id has been added to the recorder table as a primary key. This key has also been added as a foreign key to recorder_mortgage, recorder_grantor, recorder_grantee, and recorder_legal_description.`,
  },
  { level: 2, component: 'SubHeading', value: `Version 2.20` },
  {
    level: 3,
    component: 'Text',
    value: `In release 2.20, a new object has been added to the API for providing an AVM (automated valuation model) for single family residents. The object is called usa_avm and provides a range of potential values (high, median and low) along with a confidence score and date associated to the valuation.`,
  },
  { level: 2, component: 'SubHeading', value: `Version 2.21` },
  {
    level: 3,
    component: 'Text',
    value: `In release 2.21, Cherre added data to the API for Safegraph. Safegraph is a new data partner for Cherre and their data showcases businesses in the US along with the foot traffic to those businesses over time. The data is captured for 4 major markets (NYC, San Francisco, Los Angeles, and Dallas). Contact your Cherre sales rep for more information on testing the Safegraph data.

Cherre has also added tax history data to the API that covers the previous 10 years of tax assessment information on a nationwide level. The new object is called usa_tax_assessor_history and is available to all CoreAugment clients.

In addition to the tax assessor history, Cherre has also added multiple years of demographics data to the existing usa_demographics_v2 object. Right now, data is available for 2018 and 2019 and the year can be filtered using the "year" field.`,
  },
  { level: 2, component: 'SubHeading', value: `Version 2.22` },
  {
    level: 3,
    component: 'Text',
    value: `Cherre has added public data from the Census Bureau and BLS into the API for CoreAugment clients. The Census provides data related to new permits filed within a metro area broken down by unit count and month. The BLS provider unemployment data per metro on a month by month basis. The new objects from the census are called census_permit_survey_pre and census_permit_survey_post and are broken up between data before and after 2004 because the schema and census measurement system changed at that point in time. 

Cherre has also integrated data from two major partners for short-term rentals, AirDNA and All the Rooms. Both providers have information available on AirBNB and Home Away listings across NYC, Dallas, San Francisco, and Los Angeles. For access to the new partner data, please contact your sales rep at Cherre.`,
  },
  { level: 2, component: 'SubHeading', value: `Version 2.23` },
  {
    level: 3,
    component: 'Text',
    value: `Cherre has integrated data from Compstak to help power your use cases for using lease comps nationwide. We're also happy to announce that this data has been integrated within our new CoreProspect product! Give us a call to trial the data in our API or through CoreProspect.

Cherre has also made a few small but meaningful changes to our base public data layer. We've added two new fields to the tax_assessor object called "cherre_lot_size_acre_calculated" and "cherre_lot_size_sq_ft_calculated" in order to provide the highest coverage for lot sizes based on the multiple sources we have access to.

As part of Cherre's address standardization process, the standardized mailing address has been added to the tax_assessor as the "cherre_address__mailing_address" field.`,
  },
  { level: 2, component: 'SubHeading', value: `Version 2.24` },
  {
    level: 3,
    component: 'Text',
    value: `Cherre has integrated data from Clear Capital to help with the analysis of residential listing data across the US. Clear Capital data can show information such as list/sale price, days on market, property amenities, and other information about residential listings.

Relationships have also been added to connect tax_assessor to usa_bls_employment and census_permit_survey_pre/post. The connections are made by FIPS code and CBSA code to help return employment and permit data associated with a given property.`,
  },
  { level: 2, component: 'SubHeading', value: `Version 2.25` },
  {
    level: 3,
    component: 'Text',
    value: `In order to improve our overall data quality and performance of the API, Cherre has converted any empty strings to 'null'.

Cherre has incorporated sample data from 3 of our partners (RCA, Spatial.ai, and Clear Capital) into the API. API users will be able to test data from these 3 partners within Cherre’s 4 sample data markets (NYC, San Francisco, LA, and Dallas) if they are licensed to access this data.`,
  },
  { level: 2, component: 'SubHeading', value: `Version 2.26` },
  {
    level: 3,
    component: 'Text',
    value: `Cherre has added Cherre Lot Boundaries and Cherre Building Footprints.`,
  },
];

export default page11ReleaseNotes;
