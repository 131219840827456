import React from 'react';
import Typography from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';

import NavigationSectionTitle from '../NavigationSectionTitle';
import { primaryColor } from 'src/const/theme';

const NavigationContentTitle = ({
  active,
  onSectionClick,
  section,
  dropdown,
}) => {
  return (
    <NavigationSectionTitle
      active={active}
      onClick={() => {
        onSectionClick(section);
      }}
      dropdown={dropdown}
    >
      <Typography
        style={{
          color: active ? primaryColor.main : grey[800],
          fontWeight: 600,
        }}
      >
        {section.title}
      </Typography>
    </NavigationSectionTitle>
  );
};

export default NavigationContentTitle;
