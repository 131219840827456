import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import decorate from '../../utils/decorate';
import decorators from '../../utils/decorators';
import Typography from '@material-ui/core/Typography';

const Table = ({ children, classes, id }) => {
  const rows = children.trim().split('\n');
  return (
    <table className={classes.root} id={id}>
      <tbody>
        {rows.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.split('|').map((cell, cellIndex) => (
              <td key={`${rowIndex}-${cellIndex}`} className={classes.row}>
                <Typography>{decorate(cell, decorators)}</Typography>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const styles = (theme) => ({
  root: {
    tableLayout: 'fixed',
    width: '100%',
  },
  row: {
    borderBottom: 'solid 1px #e0e0e0',
    padding: 10,
    paddingRight: 40,
    color: '#000',
    width: '100%',
    wordBreak: 'break-word',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      paddingTop: 10,
      paddingBottom: 10,
      '&:first-child': {
        paddingRight: 10,
      },
    },
  },
});

export default withStyles(styles)(Table);
