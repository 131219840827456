const page2MakeYourFirstQuery = [
  { level: 1, component: 'Page', value: `Make Your First Query` },
  {
    level: 3,
    component: 'Text',
    value: `To begin using Cherre’s GraphQL API, Cherre suggests using GraphiQL to run queries and view the data returned by the API. Note that you are not required to use GraphiQL. Other options such as [[https://www.postman.com/|Postman]] and [[https://chrome.google.com/webstore/detail/graphql-playground-for-ch/kjhjcgclphafojaeeickcokfbhlegecd|GraphQL Playground]] can be leveraged.`,
  },
  {
    level: 3,
    component: 'OrderedList',
    value: `Navigate to [[https://graphiql-online.com/]]

Enter this URL - "https://graphql.cherre.com/graphql" and click on the arrow to the right of the URL.

In order to authenticate, you will need to fill in two "Keys" and two "Values" under the "Request Headers" section. See the "Key" and "Value" pairs below:
  Key = "content-type" and Value = "application/json" (this may already filled in by default)
  Key = "Authorization" and Value = "Bearer <Your Token>" 

Now you’re ready to start building GraphQL queries! The "Explorer" section on the left side of the screen will display all of the objects and fields that your API key has permission to access. By clicking on the objects, fields, and operators, you can easily build a GraphQL query to test.`,
  },
  { level: 2, component: 'SubHeading', value: `IMPORTANT NOTES` },
  {
    level: 3,
    component: 'Text',
    value: `1) New Lines instead of Line Terminators:

When you write queries using a GraphQL client (such as GraphiQL, GraphQL Playground, etc), the client automatically escapes the new lines for you.

There is a "Copy" button on the top of the GraphQL client which will automatically escape the new lines. If you copy/paste your query from your GraphQL client into code, be sure new lines are properly escaped. Some languages/environments will automatically do this for you. Others may require you to manually escape your new lines with ("\\n"). 

For more information see [[https://spec.graphql.org/June2018/#sec-Line-Terminators|GraphQL Specification for Line Terminators]]


2) Rounding Behavior:

When leveraging tools such as GraphiQL or GraphQL Playground, or any other code written in javascript, values with over 16 digits will be rounded in the response.  This is expected behavior [[https://stackoverflow.com/questions/1379934/large-numbers-erroneously-rounded-in-javascript|per the javascript spec]], and is worth calling out as a special mention because it impacts many IDs in the API (such as Cherre's 'recorder_id').  `,
  },
];

export default page2MakeYourFirstQuery;
