import React from 'react';
import NavigationSectionItem from './NavigationSectionItem';
import grey from '@material-ui/core/colors/grey';
import Typography from '@material-ui/core/Typography';
import { primaryColor } from 'src/const/theme';

const NavigationSubSections = ({
  subSections,
  activeSubPageId,
  onSectionClick,
}) => {
  return subSections.map((subSection, index) => (
    <NavigationSectionItem
      active={activeSubPageId === subSection.id}
      onClick={() => onSectionClick(subSection)}
      key={index}
    >
      <Typography
        style={{
          color:
            activeSubPageId === subSection.id ? primaryColor.main : grey[700],
        }}
      >
        {subSection.title}
      </Typography>
    </NavigationSectionItem>
  ));
};

export default NavigationSubSections;
