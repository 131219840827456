const page3GraphQLQueries = [
  { level: 1, component: 'Page', value: `GraphQL Queries` },
  { level: 2, component: 'SubHeading', value: `Simple Object Queries` },
  {
    level: 3,
    component: 'Text',
    value:
      'In order to build a basic GraphQL query, you will need to include the following at a minimum.',
  },
  {
    level: 3,
    component: 'OrderedList',
    value: `The name of the object you would like to pull data from. For example, {tax_assessor_v2} is the object used to store data about nationwide tax assessor data.
    The fields within the given object that you would like to receive from the API call.`,
  },
  {
    level: 3,
    component: 'SubHeading',
    value: 'Example - Tax Bill Amount for Tax Assessor ID',
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `query SimpleObjectQuery {
  tax_assessor_v2(where:{tax_assessor_id:{ _eq: "803112456"}}){
    tax_assessor_id
    tax_bill_amount
  }
}`,
  },
  { level: 2, component: 'SubHeading', value: `Nested Object Queries` },
  {
    level: 3,
    component: 'Text',
    value:
      'For all of the object relationships within GraphQL, Cherre uses a consistent naming convention in order to help customers understand the fields being used to connect objects. The naming convention is below.',
  },
  {
    level: 3,
    component: 'Text',
    value: '<target_object>__<target_field>',
  },
  {
    level: 3,
    component: 'Text',
    value:
      'This name can be slightly different that the actual field name for clarity.',
  },
  {
    level: 2,
    component: 'SubHeading',
    value: `Comparison Operators`,
  },
  {
    level: 3,
    component: 'Text',
    value:
      'Comparison operators are used in GraphQL queries to compare values and determine the results that should be returned.',
  },
  {
    level: 3,
    component: 'SubHeading',
    value: 'Operators Supported',
  },
  {
    level: 4,
    component: 'Table',
    value: `*_is_null*|Is Null
    *_eq*|Equals
    *_neq*|Not Equal
    *_gt*|Greater Than
    *_gte*|Greater Than or Equal
    *_lt*|Less Than
    *_lte*|Less Than or Equal
    *_in*|Contained in a List
    *_nin*|Not Contained in a List
    *_like*|Matches Case Sensitive Pattern
    *_nlike*|Does Not Match Case Sensitive Pattern`,
  },
  {
    level: 2,
    component: 'SubHeading',
    value: 'Aggregation Queries',
  },
  {
    level: 3,
    component: 'Text',
    value:
      'Aggregations can be fetched on columns along with nodes using an aggregation query.',
  },
  {
    level: 3,
    component: 'Text',
    value: 'The supported aggregation functions are:',
  },
  {
    level: 3,
    component: 'UnorderedList',
    value: `count
    avg
    max
    min
    stddev
    stddev_pop
    stddev_samp
    sum
    var_pop
    var_samp
    variance`,
  },
  {
    level: 3,
    component: 'Text',
    value: 'The name of the aggregate field is of the form:',
  },
  {
    level: 3,
    component: 'Text',
    value: `<field-name> + _aggregate`,
  },
  {
    level: 3,
    component: 'Text',
    value: 'An example would be tax_assessor_v2_aggregate.',
  },
  {
    level: 3,
    component: 'Text',
    value:
      'You can see the full specification for aggregates here in the [[https://hasura.io/docs/latest/api-reference/graphql-api/query/#aggregateobject|Hasura Aggregate Object Specification]].',
  },
  {
    level: 4,
    component: 'SubHeading',
    value: 'Example - Count of Tax Assessor Records',
  },
  {
    level: 4,
    component: 'GraphiQL',
    value: `query aggregatesCount {
    tax_assessor_v2_aggregate(where: {
    situs_state: {_eq: "IL"},
    city: {_eq: "CHICAGO"},
    lot_size_sq_ft: {_gte: 5000, _lte: 30000},
    year_built: {_gte: 2010}}){
    aggregate{
      count
    }
  }
}`,
  },
  {
    level: 4,
    component: 'SubHeading',
    value:
      'Example - Average Assessed Value of Tax Assessor Records in Chicago',
  },
  {
    level: 4,
    component: 'GraphiQL',
    value: `query averageTotalAssessedValue {
  tax_assessor_v2_aggregate(where: {city: {_eq: "CHICAGO"}, state: {_eq: "IL"}}) {
    aggregate {
      avg {
        assessed_value_total
      }
    }
  }
}`,
  },
  {
    level: 4,
    component: 'Text',
    value: 'Query response:',
  },
  {
    level: 4,
    component: 'Code',
    value: `{
  "data": {
    "tax_assessor_v2_aggregate": {
      "aggregate": {
        "avg": {
          "assessed_value_total": "41002.711400974"
        }
      }
    }
  }
}`,
  },
];

export default page3GraphQLQueries;
