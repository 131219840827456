import React from 'react';

import decorate from '../../utils/decorate';
import decorators from '../../utils/decorators';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const List = ({ classes, children, id }) => {
  const items = children.trim().split('\n');

  return (
    <div className={classes.root} id={id}>
      <ul>
        {items.map((item, index) => (
          <li key={index} className={classes.item}>
            <Typography component='div'>
              {decorate(item, decorators)}
            </Typography>
          </li>
        ))}
      </ul>
    </div>
  );
};

const styles = {
  root: {
    color: '#000',
    marginBottom: 20,
  },
  item: {
    marginBottom: 10,
  },
};

export default withStyles(styles)(List);
