import React from 'react';

const Component = ({ children, id }) => {
  return (
    <img
      id={id}
      style={{
        width: '100%',
        marginBottom: 20,
        display: 'block',
      }}
      src={children}
    />
  );
};

export default Component;
