import React from 'react';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const Title = ({ classes, children, id }) => {
  return (
    <div id={id} className={classes.root}>
      <Typography style={{ color: grey[900] }} variant='h4'>
        {children}
      </Typography>
    </div>
  );
};

const styles = (theme) => ({
  root: {
    color: '#212121',
    marginTop: 30,
    marginBottom: 30,
    fontWeight: 500,
    '&:first-child': {
      marginTop: 0,
    },
    fontSize: 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      fontWeight: 400,
    },
  },
});

export default withStyles(styles)(Title);
