const page5PaginateQueryResults = [
  { level: 1, component: 'Page', value: `Paginate Query Results` },
  {
    level: 3,
    component: 'Text',
    value: `In order to paginate through records within an object, the primary key of the object can be used for ordering and filtering. This allows you to iterate through a large dataset with a simple sequence as outlined below:

  Step 1.  Pull the first page of results (see "FirstPage" sample query).

  Step 2.  Iterate through the results, saving the id from the last record.  This value will be passed to your next query (see "AdditionalPages" sample query).

  Step 3.  Repeat Step 2 until your AdditionalPages query returns 0 records.
  `,
  },
  { level: 2, component: 'SubHeading', value: `Sample Queries` },
  {
    level: 3,
    component: 'Text',
    value: `These examples illustrate how you would pull all records from tax_assessor that fall within a given zip code.`,
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `query FirstPage($zipcode: String!) {
  tax_assessor_v2(
    where: {
    zip:{_eq:$zipcode}
  }
  order_by: { tax_assessor_id: asc }
  limit: 100
) {
    tax_assessor_id
    bed_count
    bath_count
    gross_sq_ft
  }
}`,
    variables: `{
  "zipcode":"80212"
}`,
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `query AdditionalPages($zipcode: String!, $previous_id: numeric!) {
  tax_assessor_v2(
    where: {
    zip: { _eq: $zipcode }
    tax_assessor_id: { _gt: $previous_id }
  }
  order_by: { tax_assessor_id: asc }
  limit: 100
) {
    tax_assessor_id
    bed_count
    bath_count
    gross_sq_ft
  }
}`,
    variables: `{
  "zipcode": "80212",
  "previous_id": 3869848
}`,
  },
  {
    level: 3,
    component: 'Text',
    value: `NOTE: the primary key can be negative, especially with cherre_ prefixed keys, but the lowest possible value would be -9223372036854775808.`,
  },
  { level: 2, component: 'SubHeading', value: `Incremental Updates` },
  {
    level: 3,
    component: 'Text',
    value: `After performing an initial pull of records, it is best to utilize a date field to incrementally retrieve new records and to avoid paginating through records that have already been ingested. This field will commonly be name cherre_ingest_datetime, but other tables will have a last_update_date or cherre_updated_date column. Please refer to the data dictionary to see which column is relevant.`,
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `query NewPages(
  $zipcode: String!
  $previous_id: numeric!
  $last_update_date: date!
) {
  tax_assessor_v2(
    where: {
    zip: { _eq: $zipcode }
    tax_assessor_id: { _gt: $previous_id }
    last_update_date: { _gt: $last_update_date }
  }
  order_by: { tax_assessor_id: asc }
  limit: 100
) {
    tax_assessor_id
    bed_count
    bath_count
    gross_sq_ft
    last_update_date
  }
}`,
    variables: `{
  "zipcode": "80212",
  "previous_id": 3869848,
  "last_update_date": "2021-01-01"
}`,
  },
];

export default page5PaginateQueryResults;
