export const namespace = 'INTROSPECTION_QUERY';
const ns = (name) => `${namespace}/${name}`;

export const GET_INTROSPECTION_QUERY_REQUEST = ns(
  'GET_INTROSPECTION_QUERY_REQUEST'
);
export const GET_INTROSPECTION_QUERY_SUCCESS = ns(
  'GET_INTROSPECTION_QUERY_SUCCESS'
);
export const GET_INTROSPECTION_QUERY_FAILED = ns(
  'GET_INTROSPECTION_QUERY_FAILED'
);
