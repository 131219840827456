const page6ObjectRelationships = [
  { level: 1, component: 'Page', value: `Object Relationships` },
  {
    level: 3,
    component: 'Text',
    value: `For all of the object relationships within GraphQL, Cherre uses a consistent naming convention in order to help customers understand the fields being used to connect objects. The naming convention is below:`,
  },
  {
    level: 3,
    component: 'Code',
    value: `<target_object>__<target_field> (this name can be slightly different that the actual field name for clarity)`,
  },
  {
    level: 3,
    component: 'Text',
    value: `An example between tax_assessor and recorder would be below:`,
  },
  { level: 3, component: 'Code', value: `recorder__tax_assessor_id` },
  {
    level: 2,
    component: 'SubHeading',
    value: `Bridge Objects for Many-to-many Relationships`,
  },
  {
    level: 3,
    component: 'Text',
    value: `For all of the many-to-many relationships within the data, a bridge object is used to connect between objects. The naming convention for the bridge objects includes the name of both objects separated by an underscore. (e.g. tax_assessor_usa_school)`,
  },
  {
    level: 3,
    component: 'Table',
    value: `*tax_assessor_usa_school*|Connection between tax assessor and school data nationwide.
*tax_assessor_usa_points_of_interest*|Connection between tax assessor and the points of interest data nationwide.
*tax_assessor_usa_neighborhood_boundary*|Connection between tax assessor and neighborhood boundary geometries.`,
  },
  {
    level: 3,
    component: 'Text',
    value: `The relationship naming convention when linking to a bridge table is below:`,
  },
  { level: 3, component: 'Code', value: `<bridge_table_name>__bridge` },
  {
    level: 3,
    component: 'Text',
    value: `An example of the relationship between tax_assessor and tax_assessor_usa_school is below:`,
  },
  { level: 3, component: 'Code', value: `tax_assessor_usa_school__bridge` },
  { level: 2, component: 'SubHeading', value: `Geospatial Relationships` },
  {
    level: 3,
    component: 'Text',
    value: `Finally, there are objects that are connected geospatially that use a slightly different convention and connect through an intersecting join. These joins generally connect to a boundary using <target_object>__address_point and from a boundary to addresses with cherre_address__<object>_boundary`,
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `{
  cherre_address(
    where: { display_address: { _eq: "575 8TH AVENUE, NEW YORK, NY 10018" } }
  ) {
    display_address
    parcel_boundary_v2__address_point {
      geom
      cherre_address__parcel_boundary{
        display_address
      }
    }
  }
}`,
  },
  {
    level: 3,
    component: 'Text',
    value: `Cherre lot boundaries is a new dataset from Cherre that contains unique geographic boundaries of tax parcels. Lot boundaries solves the problem of returning duplicate data due to overlapping parcels. `,
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `query lotBoundaries {
  cherre_address(where: {display_address: {_eq: "330 EAST 38TH STREET, NEW YORK, NY 10016"}}) {
    cherre_lot_boundaries_v2__address_point {
      cherre_lot_boundary_pk
      cherre_address__address_point {
        display_address
        tax_assessor_v2__property_address {
          tax_assessor_id
        }
      }
    }
  }
}`,
  },
];

export default page6ObjectRelationships;
