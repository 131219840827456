const page10QueryThroughHTTP = [
  { level: 1, component: 'Page', value: `Querying Through HTTP` },
  { level: 2, component: 'SubHeading', value: `cURL` },
  {
    level: 3,
    component: 'Text',
    value: `A simple way to make a request to our API is through constructing a [[https://curl.se/|cURL]] command in your terminal.`,
  },
  {
    level: 3,
    component: 'Code',
    mode: 'text/x-sh',
    value: `curl 'https://graphql.cherre.com/graphql' \
-X POST \
-H 'Accept-Encoding: gzip, deflate, br' \
-H 'Content-Type: application/json' \
-H 'Accept: application/json' \
-H 'Connection: keep-alive' -H 'DNT: 1' \
-H 'Authorization: Bearer YOUR_TOKEN' \
--data-binary \
'{"query":"query multipleFilters {\n  tax_assessor(\n    where: {\n      _and: [{ zip: { _eq: "10001" } }, { gross_sq_ft: { _gt: 10000 } }]\n    }\n    limit: 10\n  ) {\n    gross_sq_ft\n    recorder__tax_assessor_id(\n      order_by: { document_recorded_date: desc_nulls_last }\n      limit: 5\n    ) {\n      document_type_code\n      document_amount\n      document_recorded_date\n    }\n  }\n}\n"}' \
--compressed`,
  },
];

export default page10QueryThroughHTTP;
