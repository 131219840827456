import React from 'react';

import { withStyles } from '@material-ui/core/styles';

const Button = ({ children, classes, ...otherProps }) => {
  return (
    <div {...otherProps} className={classes.root}>
      {children}
    </div>
  );
};

const styles = {
  root: {
    background: 'rgb(255, 255, 255)',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 5px 0px',
    borderRadius: 5,
    marginLeft: 15,
    padding: 8,
    display: 'flex',
    alignItems: 'center',
    '&:active': {
      background: '#eee',
    },
  },
};

export default withStyles(styles)(Button);
