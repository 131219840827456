const page1TheBasics = [
  { level: 1, component: 'Page', value: `The Basics` },
  {
    level: 3,
    component: 'Text',
    value: `Welcome to the industry's most powerful API. This documentation covers everything you will need to connect to the API, understand the data available and the connection methods used across the foundation data set. Remember that Cherre specializes in data connection to third party and internal data, but the foundation data described in this documentation is available to any Cherre API user.`,
  },
  { level: 2, component: 'SubHeading', value: `Helpful Links` },
  {
    level: 3,
    component: 'Text',
    value: `What is [[https://graphql.org/|GraphQL]]?  [[http://graphql.org/learn/queries/|Introduction to GraphQL]]. [[https://www.howtographql.com/|How to GraphQL.]]`,
  },
  { level: 2, component: 'SubHeading', value: `URL` },
  {
    level: 3,
    component: 'Text',
    value: `"https://graphql.cherre.com/graphql"`,
  },
  {
    level: 2,
    component: 'SubHeading',
    value: `Set Up 'Authorization' HTTP Header`,
  },
  {
    level: 3,
    component: 'Text',
    value: `The header to be passed should be structured in the following way. (Reach out to Cherre support if you do not have an API key) `,
  },
  {
    level: 3,
    component: 'Code',
    value: `{"Authorization": "Bearer <YourToken>"}`,
    mode: 'javascript',
  },
  {
    level: 3,
    component: 'Text',
    value: `To learn more about JSON web tokens, visit this link - [[https://jwt.io/introduction|How do JSON Web Tokens work? ]]`,
  },
];

export default page1TheBasics;
