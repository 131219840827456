import { getIntrospectionQuery } from 'graphql';
import {
  GET_INTROSPECTION_QUERY_REQUEST,
  GET_INTROSPECTION_QUERY_SUCCESS,
  GET_INTROSPECTION_QUERY_FAILED,
} from './types';
import { requestGraphqlData } from 'src/products/shell/redux/actions/graphql-query';

export const getSchemaByIntrospectionQuery = (
  useAPI: 'coreApi' | 'analyticsApi'
) => {
  return requestGraphqlData(
    getIntrospectionQuery(),
    [
      GET_INTROSPECTION_QUERY_REQUEST,
      GET_INTROSPECTION_QUERY_SUCCESS,
      GET_INTROSPECTION_QUERY_FAILED,
    ],
    null,
    null,
    useAPI
  );
};
