import flatten from 'lodash/flatten';

export const replacePatternToComponent = (text, pattern, cb) => {
  const splitText = text.split(pattern);

  let match;
  const matches = [];

  while ((match = pattern.exec(text))) {
    matches.push(match[1]);
  }

  if (splitText.length <= 1) {
    return text;
  }

  return splitText.reduce((arr, element) => {
    if (!element) {
      return arr;
    }

    if (matches.includes(element)) {
      return [...arr, cb(element)];
    }

    return [...arr, element];
  }, []);
};

export const decorate = (text, patterns) => {
  return patterns.reduce(
    (memo, pattern) => {
      return flatten(
        memo.map((item) => {
          if (typeof item !== 'string') {
            return item;
          } else {
            return replacePatternToComponent(
              item,
              pattern.regexp,
              pattern.format
            );
          }
        })
      );
    },
    [text]
  );
};

export default decorate;
