const page8CherreStandardizationServices = [
  { level: 1, component: 'Page', value: `Cherre Standardization Services` },
  { level: 2, component: 'SubHeading', value: `Address Standardization` },
  {
    level: 3,
    component: 'Text',
    value: `Cherre provides an address standardization service that allows passing an address string to the API and returns a standardized version of the address that can be used for querying other objects by address.`,
  },
  {
    level: 3,
    component: 'Text',
    value: `A sample API call for the service is below where an address string is accepted within the “address” input parameter. The address service will accept any combination of address and zip code or city and state.`,
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `{
  address(address: "8161 Seaton Pl, MONTGOMERY Al") {
    address__cherre_address {
      display_address
    }
  }
}`,
  },
  {
    level: 2,
    component: 'SubHeading',
    value: `Utilizing The Standardized Address`,
  },
  {
    level: 3,
    component: 'Text',
    value: `We will use the standardized address returned from the address service to find the associated tax assessor, recorder and point of interest values for this address. If your organization has other datasets connected by address through Cherre, the other datasets would also be accessible from this endpoint.`,
  },
  {
    level: 3,
    component: 'Text',
    value: `Cherre has created a convenient single address hub to enter the standardized address and navigate to any other object that contains a standardized address. The connected objects will be in the form \`dataset__address\`.`,
  },
  {
    level: 3,
    component: 'Text',
    value: `Cherre does not currently guarantee the the standardized will be consistent and maintained over time.`,
  },
  {
    level: 3,
    component: 'Text',
    value: `Deprecation Notice: If you are still using the old usa_address, you should utilize cherre_address which has many advantages and is being actively maintained. `,
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `query addressTaxAssessor {
  cherre_address(where: {display_address: {_eq: "8161 SEATON PLACE, MONTGOMERY, AL 36116"}}) {
    display_address
    tax_assessor_v2__property_address {
      tax_assessor_id
      assessed_value_total
    }
    recorder_v2__address(order_by: {document_instrument_date: desc_nulls_last}) {
      recorder_id
      document_instrument_date
    }
    usa_points_of_interest__address {
      business_name
    }
  }
}
`,
  },
  { level: 2, component: 'SubHeading', value: `Optimizing Address Search` },
  {
    level: 3,
    component: 'Text',
    value: `In a single query, we can both standardize an address and connect to other datasets.`,
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `query addressTaxAssessor {
  address(address: "8161 Seaton Pl, MONTGOMERY Al") {
    cherre_address__address {
      display_address
      tax_assessor_v2__property_address {
        tax_assessor_id
        assessed_value_total
      }
      recorder_v2__address(order_by: {document_instrument_date: desc_nulls_last}) {
        recorder_id
        document_instrument_date
      }
      usa_points_of_interest__address {
        business_name
      }
    }
  }
}
`,
  },
  {
    level: 2,
    component: 'SubHeading',
    value: `Connecting Addresses From Different Datasets `,
  },
  {
    level: 3,
    component: 'Text',
    value: `This address hub endpoint is also the singular endpoint to go to navigate from the data set you are interested in to other datasets that contain addresses.`,
  },
  {
    level: 3,
    component: 'Text',
    value: `We will use the tax_assessor_id to connect to the associated points of interest object by address through the address hub.`,
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `query fromTaxAssessorToPOI {
  tax_assessor_v2(where: { tax_assessor_id: { _eq: 674576208 } }) {
    cherre_address__property_address {
      display_address
      usa_points_of_interest__address {
        business_name
      }
    }
  }
}`,
  },
  {
    level: 2,
    component: 'SubHeading',
    value: `Displaying A Standardized Address`,
  },
  {
    level: 3,
    component: 'Text',
    value: `cherre_address is also the place to find the standardized form of an address from any dataset.`,
  },
  {
    level: 3,
    component: 'Text',
    value: `We can search for a specific point of interest and return all the standardized addresses from the businesses.`,
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `query standardizedPOIAddress {
  usa_points_of_interest(where: { business_name: { _eq: "WALMART" } }) {
    business_name
    cherre_address__address {
      display_address
    }
  }
}`,
  },
  { level: 2, component: 'SubHeading', value: `Finding Alternative Addresses` },
  {
    level: 3,
    component: 'Text',
    value: `If you are looking to see different forms of an address, you can connect from cherre_address to parcel_boundary and then back to cherre_address to see all the other forms of the address that are connected to that parcel.`,
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `query altAddressesByParcelBoundary{
  cherre_address(
    where: {display_address: {_eq: "185 GREENWICH STREET, NEW YORK, NY 10007"}}
  ) {
    display_address
    parcel_boundary_v2__address_point {
      cherre_address__parcel_boundary(order_by: {display_address: desc}) {
        display_address
      }
    }
  }
}`,
  },
];

export default page8CherreStandardizationServices;
