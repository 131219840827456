import React from 'react';
import throttle from 'lodash/throttle';
import constants from 'src/const';
import StickyBox from 'react-sticky-box';

import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import Navigation from '../Navigation';
import Body from '../Body';
import { Context } from '../../index';
import { navigationElements } from '../../schema/getElements';
import grey from '@material-ui/core/colors/grey';
import useIsCompact from 'src/hooks/useIsCompact';
import { useDocumentationElements } from 'src/hooks/useDocumentationElements';
import { useHistory } from 'react-router';

const { NAV_BAR_HEIGHT } = constants;

const BasicLayout: React.FC = () => {
  const isCompact = useIsCompact();
  const { makeSectionActive, documentationType } = React.useContext(Context);
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  const pageNavigationElements = navigationElements(
    useDocumentationElements(documentationType)
  );
  const { replace } = useHistory();

  const findActiveElementId = React.useCallback(() => {
    const elements = pageNavigationElements;

    if (elements.length === 0) {
      return null;
    }

    const bodyTop = scrollContainerRef.current?.getBoundingClientRect().top;
    const bodyHeight = scrollContainerRef.current?.offsetHeight;
    let activeId;

    for (let i = 0; i < elements.length; i++) {
      const node = document.querySelector(`#${elements[i]?.id}`);
      if (!node) {
        continue;
      }

      const { top } = node.getBoundingClientRect();

      if (
        top &&
        bodyTop &&
        bodyHeight &&
        top > bodyTop &&
        top < bodyTop + bodyHeight / 3
      ) {
        activeId = elements[i].id;
        break;
      }
    }

    return activeId;
  }, [pageNavigationElements]);

  const onScroll = React.useCallback(
    throttle(() => {
      const activeElementId = findActiveElementId();

      if (!activeElementId) {
        return;
      }

      makeSectionActive?.(activeElementId);

      replace({
        hash: activeElementId,
      });
    }, 1000),
    [findActiveElementId]
  );

  React.useEffect(() => {
    scrollContainerRef.current?.addEventListener('scroll', onScroll, {
      passive: true,
    });

    return () => {
      scrollContainerRef.current?.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <Grid
      container
      style={{
        paddingLeft: isCompact ? 0 : 16,
        paddingRight: isCompact ? 0 : 16,
        minHeight: `calc(100vh - ${NAV_BAR_HEIGHT}px)`,
        backgroundColor: '#ffffff',
      }}
      justify='center'
    >
      <Grid item xs={12} xl={10}>
        <Grid container spacing={isCompact ? 0 : 2}>
          <Hidden smDown>
            <Grid item md={3}>
              <StickyBox
                style={{
                  overflow: 'auto',
                  height: `calc(100vh - ${NAV_BAR_HEIGHT + 10}px)`,
                  borderRight: `1px solid ${grey[400]}`,
                }}
                offsetTop={NAV_BAR_HEIGHT + 16}
              >
                <Navigation />
              </StickyBox>
            </Grid>
          </Hidden>

          <Grid item xs={12} md={9}>
            <div
              ref={scrollContainerRef}
              id='api-doc-body'
              style={{
                overflow: 'auto',
                height: `calc(100vh - ${NAV_BAR_HEIGHT + 10}px)`,
              }}
            >
              <Body />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BasicLayout;
