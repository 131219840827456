const page7GraphQLFunctions = [
  { level: 1, component: 'Page', value: `GraphQL Functions` },
  { level: 2, component: 'SubHeading', value: `Basic Functions` },
  {
    level: 3,
    component: 'Text',
    value: `Within Cherre’s API, you may leverage functions that we have curated for you based on popular searches. These time-saving shortcuts will require that you pass arguments in much the same way that you would pass conditions to an object query.`,
  },
  { level: 3, component: 'Text', value: `Available functions include:` },
  {
    level: 3,
    component: 'UnorderedList',
    value: `Address (“address”)
Tax Assessor Point (“tax_assessor_point_v2”)`,
  },
  {
    level: 3,
    component: 'Text',
    value: `Address (address) is a function that receives a non-standardized address and returns the Cherre standardized form of the same address. This standardized address is useful for querying connected data across many different objects.  For sample queries featuring this function please see the [[https://app.cherre.com/core-api#address-standardization--6|Address Standardization]] section of this guide.`,
  },
  {
    level: 3,
    component: 'Text',
    value: `Tax Assessor Point (“tax_assessor_point_v2”) is a function that takes a latitude and longitude pair and connects to the tax_assessor row where that point connects.`,
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `{
  tax_assessor_point_v2(args: { latitude: 34.44997, longitude: -86.94597 }) {
    tax_assessor_id
  }
}`,
  },
  { level: 2, component: 'SubHeading', value: `PostGIS Functions` },
  {
    level: 3,
    component: 'Text',
    value: `There are a number of PostGIS functions available within the Cherre API. The following functions and accepted field types are listed below:`,
  },
  {
    level: 3,
    component: 'Table',
    value: `*Function*|*Accepts Geometry*|*Accepts Geography*
*_st_contains*|*True*|*False*
*_st_crosses*|*True*|*False*
*_st_equals*|*True*|*False*
*_st_intersects*|*True*|*True*
*_st_overlaps*|*True*|*False*
*_st_touches*|*True*|*False*
*_st_within*|*True*|*False*
*_st_d_within*|*True*|*True*`,
  },
  {
    level: 3,
    component: 'Text',
    value: `The functions require the use of Query Variables in order to define the input parameters. The sample query below allows a user to search for parcel boundaries within a given radius (measured in meters) from a point.`,
  },
  { level: 3, component: 'Text', value: `Query:` },
  {
    level: 3,
    component: 'GraphiQL',
    value: `query parcel_boundary_radius($point: geography!) {
  parcel_boundary_v2(
    where: { geom: { _st_d_within: { distance: 2, from: $point } } }
    order_by: { tax_assessor_id: desc }
  ) {
    tax_assessor_id
  }
}`,
    variables: `{
  "point": {
    "type": "Point",
      "coordinates": [ -74.012017665386,40.7020963612058 ]
  }
}`,
  },
];

export default page7GraphQLFunctions;
