const page4GraphQLArguments = [
  { level: 1, component: 'Page', value: `GraphQL Arguments` },
  {
    level: 3,
    component: 'Text',
    value: `Welcome to the industry's most powerful API. This documentation covers everything you will need to connect to the API, understand the data available and the connection methods used across the foundation data set. Remember that Cherre specializes in data connection to third party and internal data, but the foundation data described in this documentation is available to any Cherre API user.`,
  },
  { level: 2, component: 'SubHeading', value: `Filtering` },
  {
    level: 3,
    component: 'Text',
    value:
      "You can use the where argument in your queries to filter results based on some field’s values (even nested objects' fields). You can even use multiple filters in the same where clause using the _and or the _or operators.",
  },
  {
    level: 3,
    component: 'Text',
    value:
      'The _not operator can be used to fetch results for which some condition does not hold true. i.e. to invert the filter set for a condition.',
  },
  {
    level: 4,
    component: 'SubHeading',
    value: 'Example - WHERE Clause',
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `query whereExample {
  tax_assessor_v2(where: {tax_assessor_id: {_eq: 960402072}}) {
    tax_assessor_id
  }
}
`,
  },
  {
    level: 4,
    component: 'SubHeading',
    value: 'Example - WHERE Not Clause',
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `query WhereNotExample {
  tax_assessor_v2(limit:100, where: {_not: {tax_assessor_id: {_eq: 960402072}}}) {
    tax_assessor_id
    }
  }`,
  },
  { level: 2, component: 'SubHeading', value: `Using OR Conditions` },
  {
    level: 3,
    component: 'Text',
    value:
      'The "or" condition in GraphQL allows you to specify multiple conditions in a query, and retrieve data that meets at least one of these conditions. This is useful for situations where you want to retrieve data based on multiple criteria, or for performing complex data filtering operations.',
  },
  {
    level: 4,
    component: 'SubHeading',
    value: 'Example - OR Clause',
  },
  {
    level: 4,
    component: 'GraphiQL',
    value: `query MyQuery {
  tax_assessor_v2(limit:10,where: {_or: [{lot_depth_ft: {_gte: 60}}, {lot_depth_ft: {_is_null: true}}], _and: {_or: [{lot_width: {_gte: 60}}, {lot_width: {_is_null: true}}], _and: {tax_assessor_id: {_gt: "0"}, city: {_eq: "MOUNTAIN VIEW"}}, state: {_eq: "CA"}, street_name: {_eq: "MONROE"}, lot_size_sq_ft: {_gte: "3000", _lte: "15000"}}}) {
    tax_assessor_id
    city
    gross_sq_ft
    lot_depth_ft
    lot_width
    }
  }`,
  },
  {
    level: 2,
    component: 'SubHeading',
    value: `Using Comparison Operators`,
  },
  {
    level: 3,
    component: 'SubHeading',
    value: 'Example - Comparison',
  },
  {
    level: 3,
    component: 'GraphiQL',
    value: `query filterExampleWithBestPractice(
  $state: String!,
  $city:String!,
  $lot_size_min:bigquery_decimal,
  $lot_size_max:bigquery_decimal,
  $building_sq_ft_min:bigquery_int,
  $building_sq_ft_max:bigquery_int,
  $market_value_min:bigquery_decimal,
  $market_value_max:bigquery_decimal,
  $year_built:bigquery_decimal
) {
    tax_assessor_v2(limit:10, where: {
    situs_state: {_eq: $state},
    city: {_eq: $city},
    lot_size_sq_ft: {_gte: $lot_size_min, _lte: $lot_size_max},
    building_sq_ft: {_gte: $building_sq_ft_min, _lte: $building_sq_ft_max},
    year_built: {_gt: $year_built},
    market_value_total: {_gte: $market_value_min, _lte: $market_value_max}
    })
  {
    tax_assessor_id
    }
  }`,
    variables: `{
    "state":"IL",
    "city": "CHICAGO",
    "lot_size_min": 5000,
    "lot_size_max": 30000,
    "building_sq_ft_min": 3000,
    "building_sq_ft_max": 50000,
    "market_value_min": 100000,
    "market_value_max": 1000000,
    "year_built":2010
  }`,
  },
  {
    level: 2,
    component: 'SubHeading',
    value: 'Geospatial Functions',
  },
  {
    level: 3,
    component: 'Text',
    value:
      'The API offers out-of-the-box geospatial queries to power your custom searches.',
  },
  {
    level: 3,
    component: 'SubHeading',
    value: 'Geospatial Functions Supported',
  },
  {
    level: 4,
    component: 'Table',
    value: `*_st_contains*|Returns TRUE if geometry B is completely inside geometry A
    *_st_equals*|Returns true if the given geometries represent the same geometry. Directionality is ignored.
    *_st_intersects*|Compares two geometries and returns true if they intersect.
    *_st_touches*|Returns TRUE if A and B intersect, but their interiors do not intersect. Equivalently, A and B have at least one point in common, and the common points lie in at least one boundary.
    *_st_within*|Returns TRUE if geometry A is completely inside geometry B.
    *_st_d_within*|Returns true if the geometries are within the specified distance of one another`,
  },
  {
    level: 3,
    component: 'SubHeading',
    value: 'Example - Find All Parcels Within a Custom Polygon',
  },
  {
    level: 4,
    component: 'GraphiQL',
    value: `query parcel_query($polygon: Geography) {
  parcel_boundary_v2(
    where: {geom: {_st_intersects: $polygon}}
    order_by: {tax_assessor_id: asc}
    limit: 10
  ) {
    cherre_parcel_boundary_pk
    tax_assessor_v2__tax_assessor_id {
      tax_assessor_id
      property_use_code_mapped
    }
  }
}`,
    variables: `{
  "polygon": "POLYGON((-90.36246838620925 38.758033570337544, -90.36246838620925 38.546605038040555, -90.13330740979934 38.546605038040555,-90.36246838620925 38.758033570337544))"
}`,
  },
  {
    level: 3,
    component: 'SubHeading',
    value: 'Example - Find Parcel Information for Longitude and Latitude',
  },
  {
    level: 4,
    component: 'GraphiQL',
    value: `query parcel_query($point: Geography!) {
  parcel_boundary_v2(
    where: {geom: {_st_intersects: $point}}
  ) {
    geom
    assessor_parcel_number
    tax_assessor_v2__tax_assessor_id {
      tax_assessor_id
    }
  }
}`,
    variables: `{
  "point": "POINT(-74.114528 40.936656)"
}`,
  },
  {
    level: 3,
    component: 'SubHeading',
    value: 'Example - Find All Properties Within 1000 Meters From a Point',
  },
  {
    level: 4,
    component: 'GraphiQL',
    value: `query parcel_query($point: Geography!) {
  parcel_boundary_v2(
    where: {geom: {_st_d_within: {distance: 1000, from: $point}}}


    limit: 10
  ) {
    cherre_parcel_boundary_pk
    tax_assessor_v2__tax_assessor_id{
      tax_assessor_id
    }
  }
}`,
    variables: `{
  "point": "POINT(-74.114528 40.936656)"
}`,
  },
  {
    level: 3,
    component: 'SubHeading',
    value: 'Example - Bounding Box Properties Search',
  },
  {
    level: 4,
    component: 'GraphiQL',
    value: `query BoundingBox {
  tax_assessor_v2(where: {
    latitude: {_lte: 28.346304, _gte: 28.255967},
    longitude: {_lte: -81.589757, _gte: -81.673011}}
  limit:100
  ) {
    tax_assessor_id
    latitude
    longitude
    zip
    msa_name
  }
}`,
  },
  {
    level: 2,
    component: 'SubHeading',
    value: `Selecting Distinct Values`,
  },
  {
    level: 3,
    component: 'Text',
    value:
      'You can fetch rows with only distinct values of a column using the distinct_on argument.',
  },
  {
    level: 3,
    component: 'Text',
    value:
      'It is typically recommended to use `order_by` along with `distinct_on` to ensure we get predictable results (otherwise any arbitrary row with a distinct value of the column may be returned).',
  },
  {
    level: 3,
    component: 'Text',
    value:
      'Note that the `distinct_on` column needs to be the first column in the order_by expression.',
  },
  {
    level: 3,
    component: 'SubHeading',
    value: 'Example - Distinct Values',
  },
  {
    level: 4,
    component: 'GraphiQL',
    value: `query distinctOnExample{
  usa_county_boundary(distinct_on: state, order_by:{state:asc}) {
    state
  }
}`,
  },
  {
    level: 2,
    component: 'SubHeading',
    value: 'Sorting Values',
  },
  {
    level: 3,
    component: 'Text',
    value:
      'Results from your query can be sorted by using the order_by argument. The order_by argument takes an array of parameters to allow sorting by multiple columns',
  },
  {
    level: 3,
    component: 'Text',
    value:
      'The sort order (ascending vs. descending) is set by specifying _asc or _desc after the column name in the order_by argument.',
  },
  {
    level: 3,
    component: 'Text',
    value:
      'By default, null values are returned at the end of the results. Null values can be fetched first by adding _nulls_first after the sorting order.',
  },
  {
    level: 3,
    component: 'Text',
    value: 'The full set of options for ordering are below:',
  },
  {
    level: 4,
    component: 'UnorderedList',
    value: `desc
    desc_nulls_first
    desc_nulls_last
    asc
    asc_nulls_first
    asc_nulls_last
    `,
  },
  {
    level: 3,
    component: 'SubHeading',
    value: 'Example - Sorting',
  },
  {
    level: 4,
    component: `GraphiQL`,
    value: `query sortingExample {
  tax_assessor_v2(
    distinct_on:situs_county,
    where:{situs_state:{_eq:"CA"}},
    order_by:{situs_county:desc_nulls_first}
  ){
    situs_county,
    situs_state
  }
}`,
  },
];

export default page4GraphQLArguments;
