import React, { useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import ApiSearchAutoComplete from './ApiSearchAutoComplete';

const searchClient = algoliasearch(
  'UV6J3EVVLR',
  '1884fd0af720ad1afdaaa9ce0ec77231'
);

type ApiSearchInputProps = {
  placeholder: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const ApiSearchInput: React.FC<ApiSearchInputProps> = ({
  placeholder,
  onChange,
}) => {
  const [query, setQuery] = useState('');
  return (
    <InstantSearch indexName='dev_API_SEARCH' searchClient={searchClient}>
      <Configure hitsPerPage={5} />
      <ApiSearchAutoComplete
        onChange={onChange}
        placeholder={placeholder}
        query={query}
        onSuggestionSelected={(_, { suggestion }) => setQuery(suggestion.name)}
        onSuggestionCleared={() => setQuery('')}
      />
    </InstantSearch>
  );
};

export default ApiSearchInput;
