const page01SampleAddressQuery = [
  { level: 1, component: 'Page', value: `Sample Address Query` },
  {
    level: 3,
    component: 'Text',
    value: `The code snippet demonstrates how to query an address using Cherre’s GraphQL API to retrieve standardized address data and related property details.`,
  },
  { level: 2, component: 'SubHeading', value: `Authorization` },
  {
    level: 3,
    component: 'Text',
    value: `The script uses an API token to authenticate requests. This token is included in the request headers for secure access to the API.`,
  },
  { level: 2, component: 'SubHeading', value: `Query` },
  {
    level: 3,
    component: 'Text',
    value: `The Query variable holds a GraphQL query that requests information for a specified address ("111 bobolink ct, wayne, nj"). The query is structured to return:
	•	display_address: A standardized version of the address.
	•	tax_assessor_id: The tax assessor identifier, nested under tax_assessor_v2__property_address.`,
  },
  {
    level: 2,
    component: 'SubHeading',
    value: `Response`,
  },
  {
    level: 3,
    component: 'Text',
    value: `If the response code indicates success (200), the script parses and formats the JSON output for easy readability. If an error occurs, an exception is raised with the response’s status code and message. `,
  },
  {
    level: 3,
    component: 'Code',
    mode: 'python',
    value: `
import json
import requests

# User credentials
headers = {'Authorization': f'Bearer {token}'}
# API endpoint URL
url = 'https://graphql.cherre.com/graphql'

def parse_address_dict_to_str(x):
    try:
        return x[0]['display_address']
    except:
        return None

query = """
{
  address(address: "111 bobolink ct, wayne, nj") {
    cherre_address__address {
      display_address
      tax_assessor_v2__property_address {
        tax_assessor_id
      }
    }
  }
}

"""

response = requests.post(url, headers=headers, json={'query': query})

if response.status_code == 200:
    output = response.json()
    print(json.dumps(output, indent=2))
else:
    raise Exception(f"Query failed to run by returning code of {response.status_code}. {response.text}")
    `,
  },
];

export default page01SampleAddressQuery;
