const page4AvailableObjects = [
  { level: 1, component: 'Page', value: `Available Objects` },
  {
    level: 3,
    component: 'Text',
    value: `The following objects are available out of the box to all Cherre API clients.`,
  },
  {
    level: 3,
    component: 'Table',
    value: `*Object Name*|*Object Description*|*pk/ordering key name*|*pk/ordering key datatype*
*census_permit_survey_post*|New permits filed nationwide from the Census (post-2004)|cbsa_code|String
*census_permit_survey_pre*|New permits filed nationwide from the Census (pre-2004)|msa_cmsa|String
*cherre_address*|Nationwide standardized address inventory|address_id|numeric
*cherre_building_footprints*|US, UK, and CA building footprint inventory|cherre_building_footprint_pk|String
*cherre_lot_boundaries*|Flattened US tax parcels|cherre_lot_boundary_pk|String
*parcel_boundary_v2*|Nationwide parcel boundary geometry data|tax_assessor_id|numeric
*recorder_v2*|Nationwide recorder data|recorder_id|numeric
*recorder_grantee_v2*|Nationwide grantee data from recorder records|cherre_recorder_grantee_pk|bigint
*recorder_grantor_v2*|Nationwide grantor data from recorder records|cherre_recorder_grantor_pk|bigint
*recorder_legal_description*|Nationwide legal description data from recorder records|cherre_recorder_legal_description_pk|bigint
*recorder_mortgage_v2*|Nationwide mortgage data from recorder records|cherre_recorder_mortgage_pk|bigint
*tax_assessor_v2*|Nationwide tax assessor data|tax_assessor_id|numeric
*tax_assessor_block*|Nationwide block data from tax assessor records|cherre_tax_assessor_block_pk|bigint
*tax_assessor_lot*|Nationwide lot data from tax assessor records|cherre_tax_assessor_lot_pk|bigint
*tax_assessor_owner_v2*|Nationwide owner data from tax assessor records|cherre_tax_assessor_owner_pk|bigint
*tax_assessor_usa_neighborhood_boundary*|Bridge table|na|na
*tax_assessor_usa_school*|Bridge table|na|na
*usa_avm*|Nationwide residential valuations|cherre_usa_avm_pk|bigint
*usa_bls_employment*|Nationwide employment numbers from the BLS|cherre_usa_bls_employment_pk|numeric
*usa_census_places_boundary*|Nationwide geometry data defining census designated places|geography_id|String
*usa_census_places_boundary_tax_assessor*|Bridge table|na|numeric
*usa_census_tract_boundary*|Nationwide geometry data defining census tract boundaries|geo_id_pk|String
*usa_county_boundary*|Nationwide geometry data defining county boundaries|county_fips_code_pk|String
*usa_demographics_v2*|Nationwide demographics, crime, weather, and other community level data|cherre_usa_demographics_pk|bigint
*usa_msa_census_boundary*|Nationwide geometry data defining metropolitan statistical areas|cbsa_fips_code|String
*usa_neighborhood_boundary*|Nationwide neighborhood geometry data|geography_id|String
*usa_owner_unmask_v2*|Cherre's nationwide unmasked ownership data that provide owners and related entities of records in tax_assessor|cherre_usa_owner_unmask_pk|bigint
*usa_points_of_interest*|Nationwide points of interest (e.g. restaurants, bars, shopping)|poi_id|numeric
*usa_points_of_interest_usa_neighborhood_boundary*|Bridge table|na|na
*usa_school*|Nationwide school profile data|institution_id|numeric
*usa_school_boundary*|Nationwide geometry data defining school boundaries|geography_id|String
*usa_state_boundary*|Nationwide geometry data defining state boundaries|state_fips_code_pk|String
*usa_tax_assessor_history*|Nationwide tax assessor history going back 10 years|cherre_usa_tax_assessor_history_pk|bigint
*usa_zip_code_boundary_v2*|Nationwide zip code boundaries|geography_id|String
*usa_zip_code_boundary_v2_usa_neighborhood_boundary*|Bridge table|na|na`,
  },
];

export default page4AvailableObjects;
